import {DflRegionType, NavigationOverlayStateType} from "../../models";

export interface SystemState {
  selectedRegion: DflRegionType;
  timezoneDetected: boolean;
  utcOffset: number;
  localUTCOffsetText: string;
  overlayState: NavigationOverlayStateType;
  isNavigationHidden: boolean;
  isNavigationInverted: boolean;
  isHomepageAboveVideo: boolean;
  isTextFilterActive: boolean;
  currentJwPlayerId: string;
  userDidConsent: boolean;
  screenWidth: number;
}

// actions
export const UPDATE_SELECTED_REGION = "UPDATE_SELECTED_REGION";
interface UpdateRegion {
  type: typeof UPDATE_SELECTED_REGION;
  payload: DflRegionType;
}

export const SET_TIMEZONE_DETECTED = "SET_TIMEZONE_DETECTED";
interface SetTimezoneDetected {
  type: typeof SET_TIMEZONE_DETECTED;
  payload: boolean;
}

export const SET_USER_DID_CONSENT = "SET_USER_DID_CONSENT";
interface SetUserDidConsent {
  type: typeof SET_USER_DID_CONSENT;
  payload: boolean;
}

export const UPDATE_UTC_OFFSET = "UPDATE_UTC_OFFSET";
interface UpdateUTCOffset {
  type: typeof UPDATE_UTC_OFFSET;
  payload: number;
}

export const UPDATE_LOCAL_UTC_OFFSET_TEXT = "UPDATE_LOCAL_UTC_OFFSET_TEXT";
interface UpdateLocalUTCOffsetText {
  type: typeof UPDATE_LOCAL_UTC_OFFSET_TEXT;
  payload: string;
}

export const UPDATE_NAVIGATION_OVERLAY = "UPDATE_NAVIGATION_OVERLAY";
interface UpdateNavigationOverlay {
  type: typeof UPDATE_NAVIGATION_OVERLAY;
  payload: NavigationOverlayStateType;
}

export const UPDATE_IS_NAVIGATION_HIDDEN = "UPDATE_IS_NAVIGATION_HIDDEN";
interface UpdateIsNavigationHidden {
  type: typeof UPDATE_IS_NAVIGATION_HIDDEN;
  payload: boolean;
}

export const UPDATE_IS_HOMEPAGE_ABOVE_VIDEO = "UPDATE_IS_HOMEPAGE_ABOVE_VIDEO";
interface UpdateIsHomepageAboveVideo {
  type: typeof UPDATE_IS_HOMEPAGE_ABOVE_VIDEO;
  payload: boolean;
}

export const UPDATE_IS_NAVIGATION_INVERTED = "UPDATE_IS_NAVIGATION_INVERTED";
interface UpdateIsNavigationInverted {
  type: typeof UPDATE_IS_NAVIGATION_INVERTED;
  payload: boolean;
}

export const UPDATE_IS_TEXTFILTER_ACTIVE = "UPDATE_IS_TEXTFILTER_ACTIVE";
interface UpdateIsTextFilterActive {
  type: typeof UPDATE_IS_TEXTFILTER_ACTIVE;
  payload: boolean;
}
export const SET_CURRENT_JW_PLAYER_ID = "SET_CURRENT_JW_PLAYER_ID";
interface SetCurrentJwPlayerId {
  type: typeof SET_CURRENT_JW_PLAYER_ID;
  payload: string;
}

export const UPDATE_SCREENWIDTH = "UPDATE_SCREENWIDTH";
interface UpdateScreenWidth {
  type: typeof UPDATE_SCREENWIDTH;
  payload: number;
}

export type SystemActionTypes =
  | UpdateRegion
  | SetTimezoneDetected
  | UpdateUTCOffset
  | UpdateLocalUTCOffsetText
  | UpdateNavigationOverlay
  | UpdateIsNavigationHidden
  | UpdateIsNavigationInverted
  | UpdateIsTextFilterActive
  | SetCurrentJwPlayerId
  | SetUserDidConsent
  | UpdateScreenWidth
  | UpdateIsHomepageAboveVideo;
