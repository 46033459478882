import {weekDays, rundownTimeMap, RundownSelectMenuValues} from "../../models";
import {rundownDatesSelectOptionsDesktop} from "../../util/content";

import {
  UPDATE_SELECTED_DATE_OPTION,
  UPDATE_SELECTED_WEEK_DAY,
  UPDATE_SELECTED_PREMATCH_START_AND_END_TIME,
  UPDATE_SELECTED_LIVEMATCH_FIRST_HALF_START_AND_END_TIME,
  UPDATE_SELECTED_LIVEMATCH_SECOND_HALF_START_AND_END_TIME,
  UPDATE_SELECTED_POST_MATCH_START_AND_END_TIME,
  UPDATE_SELECTED_DIGITAL_HIGHLIGHTS_CLIPS_TIME,
  UPDATE_SELECTED_SLOW_MOTION_PLAY_OUTS_TIME,
  UPDATE_SELECTED_POST_MATCH_INTERVIEWS_TIME,
  RundownState,
  RundownActionTypes,
} from "./types";

const initialState: RundownState = {
  selectedWeekDay: weekDays.saturday,
  selectedDateOption: {
    value: rundownDatesSelectOptionsDesktop[1].value,
    label: rundownDatesSelectOptionsDesktop[1].label,
  },
  selectedPreMatchStartAndEndTime: [
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).preMatchStart.hours,
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).preMatchStart.minutes,
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).preMatchEnd.hours,
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).preMatchEnd.minutes,
  ],
  selectedLiveMatchFirstHalfStartAndEndTime: [
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).liveMatchFirstHalfStart.hours,
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).liveMatchFirstHalfStart.minutes,
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).liveMatchFirstHalfEnd.hours,
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).liveMatchFirstHalfEnd.minutes,
  ],
  selectedLiveMatchSecondHalfStartAndEndTime: [
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).liveMatchSecondHalfStart.hours,
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).liveMatchSecondHalfStart.minutes,
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).liveMatchSecondHalfEnd.hours,
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).liveMatchSecondHalfEnd.minutes,
  ],
  selectedPostMatchStartAndEndTime: [
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).postMatchStart.hours,
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).postMatchStart.minutes,
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).postMatchEnd.hours,
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).postMatchEnd.minutes,
  ],
  selectedDigitalHighlightsClipsTime: [
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).digitalHightlightClips.hours,
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).digitalHightlightClips.minutes,
  ],
  selectedSlowMotionPlayOutsTime: [
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).slowMotionPlayOuts.hours,
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).slowMotionPlayOuts.minutes,
  ],
  selectedPostMatchInterviewsTime: [
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).postMatchInterviews.hours,
    rundownTimeMap.get(RundownSelectMenuValues.saturdayAfternoon).postMatchInterviews.minutes,
  ],
};

export function rundownReducer(state = initialState, action: RundownActionTypes): RundownState {
  switch (action.type) {
    case UPDATE_SELECTED_DATE_OPTION: {
      return {
        ...state,
        selectedDateOption: action.payload,
      };
    }
    case UPDATE_SELECTED_WEEK_DAY: {
      return {
        ...state,
        selectedWeekDay: action.payload,
      };
    }

    case UPDATE_SELECTED_PREMATCH_START_AND_END_TIME: {
      return {
        ...state,
        selectedPreMatchStartAndEndTime: action.payload,
      };
    }

    case UPDATE_SELECTED_LIVEMATCH_FIRST_HALF_START_AND_END_TIME: {
      return {
        ...state,
        selectedLiveMatchFirstHalfStartAndEndTime: action.payload,
      };
    }

    case UPDATE_SELECTED_LIVEMATCH_SECOND_HALF_START_AND_END_TIME: {
      return {
        ...state,
        selectedLiveMatchSecondHalfStartAndEndTime: action.payload,
      };
    }

    case UPDATE_SELECTED_POST_MATCH_START_AND_END_TIME: {
      return {
        ...state,
        selectedPostMatchStartAndEndTime: action.payload,
      };
    }

    case UPDATE_SELECTED_DIGITAL_HIGHLIGHTS_CLIPS_TIME: {
      return {
        ...state,
        selectedDigitalHighlightsClipsTime: action.payload,
      };
    }

    case UPDATE_SELECTED_SLOW_MOTION_PLAY_OUTS_TIME: {
      return {
        ...state,
        selectedSlowMotionPlayOutsTime: action.payload,
      };
    }

    case UPDATE_SELECTED_POST_MATCH_INTERVIEWS_TIME: {
      return {
        ...state,
        selectedPostMatchInterviewsTime: action.payload,
      };
    }

    default:
      return state;
  }
}
