import {DflRegionType} from "../models/constants";
import store from "../store";

const DEBUG = false;

export enum SubNavType {
  Product = "Product",
  Region = "Region",
}

const VIEWED_MODULES = new Set();

// this is a workaround to ensure that the title of the page
// is tracked correctly on initial load. The gatsby hook
// which records the route change fires before the react
// helmet component is rendered to set the page title. If
// no page title is set, try a basic exponential back-off
const waitOnTitle = ({waitTime, callback, timeout}: {waitTime: number; callback: () => void; timeout: number}) => {
  if (typeof document === "undefined") {
    // avoid errors when gatsby generates pages in a node
    // environment during build
    callback();
    return;
  }

  if (document.title) {
    callback();
    return;
  }

  const newWait = waitTime * 2;

  if (newWait >= timeout) {
    throw new Error(`Tracking code timed out after ${timeout / 1000} seconds waiting for the page title to be set`);
  }

  setTimeout(() => waitOnTitle({waitTime: waitTime * 2, callback, timeout}), waitTime * 2);
};

const dataLayerPush = (data: any) => {
  if (!window.dataLayer) {
    return;
  }
  if (DEBUG) {
    console.log("GTM event", data);
  }
  window.dataLayer.push(data);
};

export const trackRegion = (region: DflRegionType) =>
  dataLayerPush({
    localization: region,
    productCategory: "Digital Content",
  });

export const trackPageView = (path: string) => {
  if (typeof document === "undefined") {
    return;
  }
  const appState = store.getState();

  waitOnTitle({
    waitTime: 100,
    timeout: 2000,
    callback: () =>
      dataLayerPush({
        event: "bundesligaEvent",
        virtPath: path,
        virtPageTitle: document.title,
        localization: appState.system.selectedRegion,
        productCategory: "Digital Content",
      }),
  });
};

export const trackNavClick = (label: string) =>
  dataLayerPush({event: "gaEvent", eventCategory: "Header", eventAction: "Navigation - Standard", eventLabel: label});

export const trackSubNavClick = (opts: {isMobile: boolean; type: SubNavType; label: string}) => {
  const navType = opts.isMobile ? "Mobile" : "Standard";
  dataLayerPush({
    event: "gaEvent",
    eventCategory: "Header",
    eventAction: `Navigation - ${navType}`,
    eventLabel: `${opts.type} | ${opts.label}`,
  });
};

export const trackSearchButtonClick = () =>
  dataLayerPush({event: "gaEvent", eventCategory: "Header", eventAction: "Search", eventLabel: "Open Search Tab"});

export const trackMainLogoClick = () =>
  dataLayerPush({event: "gaEvent", eventCategory: "Header", eventAction: "Logo", eventLabel: "Bundesliga"});

export const trackMobileMenuToggle = (isMaximizing: boolean) =>
  dataLayerPush({
    event: "gaEvent",
    eventCategory: "Header",
    eventAction: "Navigation - Mobile",
    eventLabel: isMaximizing ? "Maximize" : "Minimize",
  });

export const trackMobileLocalizationChange = (newRegion: string) => {
  dataLayerPush({
    event: "gaEvent",
    eventCategory: "Header",
    eventAction: "Navigation - Mobile",
    eventLabel: `Region | ${newRegion}`,
  });
};

export const trackMobileProductClick = (product: string) => {
  dataLayerPush({
    event: "gaEvent",
    eventCategory: "Header",
    eventAction: "Navigation - Mobile",
    eventLabel: `Product | ${product}`,
  });
};

export const trackFooterLinkClick = (label: string) =>
  dataLayerPush({event: "gaEvent", eventCategory: "Footer", eventAction: "Navigation", eventLabel: label});

export const trackModuleImpression = (moduleName: string) => {
  // only record a single tracking event per module
  // until the page gets reloaded
  if (VIEWED_MODULES.has(moduleName) || typeof document === "undefined") {
    return;
  }
  VIEWED_MODULES.add(moduleName);
  dataLayerPush({
    event: "gaEvent",
    eventCategory: "Module Impression",
    eventAction: document.location.pathname,
    eventLabel: moduleName,
  });
};

export const trackSuggestionClick = ({searchTerm, page}: {searchTerm: string; page: string}) =>
  dataLayerPush({
    event: "gaEvent",
    eventCategory: "Search Term Tracking",
    eventAction: searchTerm,
    eventLabel: page,
  });

export const trackInternalLinkClick = ({link, name}: {name: string; link: string}) =>
  dataLayerPush({
    event: "gaEvent",
    eventCategory: "Internal Link",
    eventAction: name,
    eventLabel: link,
  });

export const trackVideoPlayerAction = ({videoId, action}: {videoId: string; action: string}) =>
  dataLayerPush({event: "interaction", eventCategory: "Videos", eventAction: action, eventLabel: videoId});
