import {createStore, combineReducers} from "redux";
import {SystemState} from "./system/types";
import {systemReducer} from "./system/reducers";
import {ScheduleState} from "./schedule/types";
import {scheduleReducer} from "./schedule/reducers";
import {RundownState} from "./rundown/types";
import {rundownReducer} from "./rundown/reducers";

export interface AppState {
  system: SystemState;
  schedule: ScheduleState;
  rundown: RundownState;
}
const rootReducer = combineReducers<AppState>({
  system: systemReducer,
  schedule: scheduleReducer,
  rundown: rundownReducer,
});
const store = createStore(rootReducer);
// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

export default store;
