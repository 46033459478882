import moment, {Moment} from "moment";

import {
  DflRegionType,
  dflRegions,
  colors,
  WeekDayType,
  weekDayMap,
  DFL_UTC_OFFSET,
  WPFieldGroupContactPersonProps,
  WPFieldGroupContactMainProps,
  ContactPersonProps,
  ContactMainProps,
  WPPageNodeProps,
  ProductCategory,
  WPFieldGroupThreeColumnsColumn,
  DescriptionColumnProps,
  categoryClasses,
  LocalizedImageProps,
  LocalizedVideoProps,
  ProductLocalizedImageProps,
  RelatedProductsProps,
  contentTypes,
  ProductNode,
} from "../models";
import {dflRegionByString, categoryWordpressKeyMap} from "../models/valueMaps";

/**
 * gets the designated DFL Market by the timezone (see https://www.wikiwand.com/en/List_of_tz_database_time_zones)
 *
 */
export const getDflRegionByTimezone = (): DflRegionType => {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const regionString = timeZoneString.match(/.*(?=\/)/)[0];
  const determinedDflRegion = dflRegionByString.get(regionString);
  return determinedDflRegion === undefined ? dflRegions.emea : determinedDflRegion;
};

/**
 * transforms the wordpress response for a contact person into properties for the contactCardPerson component
 *
 */
export const acfFieldsToContactPersons = (fields: WPFieldGroupContactPersonProps[]): ContactPersonProps[] => {
  return fields.map(field => ({
    avatar: field.image ? field.image.sourceUrl : "",
    ...field,
  }));
};

/**
 * transforms the wordpress response for a main contact into properties for the contactCardMain component
 *
 */
export const acfFieldToMainContact = (field: WPFieldGroupContactMainProps): ContactMainProps => {
  return field;
};

/**
 * transforms the wordpress response for a category into properties for the categoryList component
 *
 */
export const wpPageNodeToCategory = (edge: WPPageNodeProps): ProductCategory => {
  const {name, description, categoryclass, image} = edge.node.category;
  return {
    title: name,
    description,
    slug: edge.node.slug,
    color:
        categoryclass === categoryClasses.matchday
        ? colors.redDFL
        : categoryclass === categoryClasses.promotion
        ? colors.grayMediumDark
        : colors.grayMedium,
    image: image ? image.sourceUrl : null,
  };
};

/**
 * transforms the wordpress response of threecolumn field group into the properties
 * for the ThreeColumn component
 *
 */
export const responseToDescriptionColumnProps = (data: WPFieldGroupThreeColumnsColumn[]): DescriptionColumnProps[] => {
  return data.map(column => {
    const descriptions = column.bullets.map(bullet => bullet.text);
    return {
      title: column.title,
      descriptions,
    };
  });
};

/**
 * transforms the wordpress response of product nodes to an array of properties for the
 * "Related Products" module
 *
 */
export const createRelatedProductsProps = (relatedProducts: ProductNode[]): RelatedProductsProps[] => {
  return relatedProducts.map(product => productToRelatedProductProps(product));
};

/**
 * transforms the wordpress response of product nodes to an array of properties for the
 * "Related Products" module
 *
 */
export const productToRelatedProductProps = (product: ProductNode): RelatedProductsProps => {
  const {
    productContentType: type,
    productImageOrVideoSettings: settings,
    productThreeColumnModule: threeColumn,
    productCategory: category,
  } = product.node.product_details.productContent;

  const title =
    type !== contentTypes.threeColumn
      ? settings.productImageOrVideoHeadline.trim()
      : threeColumn.overallTitle.trim();

  // the category slug can be derived from the label (from WP category) except for "Promo & Trailer Content"
  const categorySlug = categoryWordpressKeyMap.get(category[0]);

  // the product anchor slug can be derived from the image/video headline or the first column's headline of a threeColumn module
  const productAnchorSlug =
    type !== contentTypes.threeColumn
      ? slugify(title)
      : slugify(threeColumn.threeColumnLocalizedContentColumnOne.headline.trim());

  return {
    overline: category[1],
    title,
    link: `${categorySlug}/#${productAnchorSlug}`,
  };
};

/**
 * get the span between a number of columns (including column gap)
 *
 */
export const columnSpan = (columns: number, colWidth: number, gapWidth: number): number => {
  return columns * colWidth + Math.ceil(columns - 1) * gapWidth;
};

/**
 * function description
 *
 */
export const categorySlugFromUrl = (url: string): string => {
  const pieces = url.split("/");
  return pieces[pieces.length - 2];
};

/**
 * replaces all whitespace characters by a  dash (minus character).
 *
 */
export const slugify = (name: string): string => {
  return name.replace(/ /g, "-").toLowerCase();
};

/**
 * replaces all dashes (minus character) by a whitespace character.
 *
 */
export const unslugify = (slug: string): string => {
  return slug.replace(/-/g, " ");
};

/**
 * capitalizes the first character of each word in a sentence.
 *
 */
export const toTitleCase = (input: string): string => {
  return input.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

/**
 * splits a string at linebreaks and returns each line as an array entry
 *
 */
export const linesToTextArray = (text: string): string[] => {
  return text.split("\r\n");
};

/**
 * returns an (url) string by a selected DflRegionType or null, if the string can't be found.
 *
 */
export const getLocalizedImage = (
  responseLocalizedImages: LocalizedImageProps,
  selectedRegion: DflRegionType,
): string | undefined => {
  const {imageEmea: productEmea, imageAmericas: productAmericas, imageAsia: productAsia} =
    responseLocalizedImages || {};

  const {sourceUrl: emeaSourceUrl} = productEmea || {};
  const {sourceUrl: americasSourceUrl} = productAmericas || {};
  const {sourceUrl: asiaSourceUrl} = productAsia || {};

  if (selectedRegion === dflRegions.emea) {
    return emeaSourceUrl;
  } else if (selectedRegion === dflRegions.americas) {
    return americasSourceUrl;
  } else if (selectedRegion === dflRegions.asia) {
    return asiaSourceUrl;
  }
};

/**
 * returns an (url) string by a selected DflRegionType or null, if the string can't be found.
 *
 */
export const getLocalizedProductImage = (
  responseLocalizedImages: ProductLocalizedImageProps,
  selectedRegion: DflRegionType,
): string | undefined => {
  const {
    productLocalizedImageImageEmea: productEmea,
    productLocalizedImageImageAmericas: productAmericas,
    productLocalizedImageImageAsia: productAsia,
  } = responseLocalizedImages || {};

  const {sourceUrl: emeaSourceUrl} = productEmea || {};
  const {sourceUrl: americasSourceUrl} = productAmericas || {};
  const {sourceUrl: asiaSourceUrl} = productAsia || {};

  if (selectedRegion === dflRegions.emea) {
    return emeaSourceUrl;
  } else if (selectedRegion === dflRegions.americas) {
    return americasSourceUrl;
  } else if (selectedRegion === dflRegions.asia) {
    return asiaSourceUrl;
  }
};

/**
 * returns a string by a selected DflRegionType or null, if the string can't be found.
 *
 */
export const getLocalizedVideo = (
  responseLocalizedVideos: LocalizedVideoProps,
  selectedRegion: DflRegionType,
): string | undefined => {
  if (selectedRegion === dflRegions.emea) {
    return responseLocalizedVideos.videoEmeaId;
  } else if (selectedRegion === dflRegions.americas) {
    return responseLocalizedVideos.videoAmericasId;
  } else if (selectedRegion === dflRegions.asia) {
    return responseLocalizedVideos.videoAsiaId;
  }
};

/**
 * adds commas to number for thousands seperator
 *
 */
export const addCommasToNumber = (value: number, max: number): string => {
  const parts = value.toString().split(".");
  if (max < 10 && parts.length === 1) {
    parts.push('0');
  }
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

/**
 * create a moment.js instance for a broadcast product with CET Time (UTC+1:00)
 *
 */
export const createBroadcastDate = (weekday: WeekDayType, hour: number, minute: number): Moment => {
  return moment()
    .utcOffset(DFL_UTC_OFFSET)
    .day(weekDayMap.get(weekday).fullName)
    .hour(hour)
    .minute(minute)
    .second(0)
    .millisecond(0);
};

/**
 * left-pads a number below 10 with a 0 (e.g. 9 => 09)
 *
 */
export const leftPad = (num: number): string => {
  return num < 10 ? `0${num}` : `${num}`;
};

/**
 * displays the broadcast time in local time
 *
 * example local times:
 * Berlin, Germany = UTC+2
 * Auckland, New Zealand = UTC+12
 * Honolulu, USA = UTC-10
 *
 */
export const displayBroadcastTimeByLocalTimezoneOffset = (
  localUTC: number,
  broadcastDate: moment.Moment,
  columnDay: WeekDayType,
): string => {
  const hourDifference = localUTC - broadcastDate.utcOffset() / 60;
  // e.g. difference Berlin and Honolulu is -12 hours

  const isHourDifferenceAFloatingNumber = hourDifference % 1 !== 0;
  const roundedHourDifference = isHourDifferenceAFloatingNumber
    ? Math.ceil(hourDifference)
    : Math.floor(hourDifference);

  // create a date object for the columnDay
  const columnDayDate = createBroadcastDate(columnDay, 12, 0);

  // compare the broadcast day against the columnDay (e.g. the Slow Motion Playouts for the Friday Live Match are actually
  // available 00:30 on Saturday, but are listed in the Friday column for clarity)
  const differenceBroadcastDayAndColumnDay = Math.abs(columnDayDate.day() - broadcastDate.day());

  // calculate the day difference by the UTC offset
  // e.g. broadcast time 19:30 in Berlin is 07:30 in Honolulu, same day, but broadcast
  // time 01:30 on a Friday in Berlin is 13:30 on a Thursday in Honolulu

  const newHours = broadcastDate.hour() + roundedHourDifference;
  const differenceByOffset = newHours > 23 ? 1 : newHours < 0 ? -1 : 0;

  // add the broadcast difference and the offset difference together (can be positive or negative)
  const daysToAdd = differenceBroadcastDayAndColumnDay + differenceByOffset;

  // if the daysToAdd is not 0, it means we are either a day ahead or behind. In this case, create a suffix
  // e.g. Slow Motion Playout for the friday live match:  00:30 (Sat)
  const suffix =
    daysToAdd !== 0
      ? ` (${columnDayDate
          .clone()
          .add(daysToAdd, "d")
          .format("ddd")})`
      : "";

  // prettier-ignore
  let displayHours = newHours > 23
    ? newHours - 24  // e.g. 03:00 instead of 27:00
    : newHours < 0
      ? newHours + 24  // e.g. 19:00 instead of -5:00
      : newHours;

  let displayMinutes = leftPad(broadcastDate.minute());

  // If the hour difference is a floating number then we use the values
  // after the decimal point to caculate the minutes difference.
  // e.g. a Broadcast time of 15:30 in Berlin, is 00:00 in Alice Springs,
  // Australia, because ACST UTC is +9.3, or when local time is set to
  // Nepal in Kathmandu then broadcast time is 20:15 because NPT UTC is +5.45.
  // This is a list of UTC Offsets for reference:
  // https://en.wikipedia.org/wiki/List_of_UTC_time_offsets
  const minuteDifference = isHourDifferenceAFloatingNumber ? hourDifference % 1 : 0;
  const convertMinutesToQuarters = minuteDifference * 60;
  const halfOrFifteenMinutes =
    convertMinutesToQuarters === 45
      ? 15
      : convertMinutesToQuarters === 30
      ? 30
      : convertMinutesToQuarters === -30
      ? 30
      : 0;
  let localDisplayMinutes = parseInt(displayMinutes, 10) - halfOrFifteenMinutes;

  // if localDisplayMinutes has a difference that is past the hour then
  // we change the localMinutes to the correct minutes for the previous hour.
  // e.g. a Broadcast time with a pre-match end time of 15:20 (Sat) in Berlin is
  // equal to 23:50 (Sun) in Darwin, Australia becaue ACST UTC Offset is +9.3.
  if (localDisplayMinutes < 0) {
    localDisplayMinutes = 60 - Math.abs(localDisplayMinutes);
    displayHours = displayHours - 1;
    // if displayHours is 00 (Midnight) then we show 23 (11pm).
    if (displayHours < 0) {
      displayHours = 23;
    }
  }

  displayMinutes = leftPad(localDisplayMinutes);

  return `${leftPad(displayHours)}:${displayMinutes}${suffix}`;
};

/*
 * escapes special characters in a string to be used in regular expressions (e.g. "\" becomes "\\", "." becomes "\.")
 *
 */
export const escapeRegexCharacters = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

/**
 * cut off text after a given maximum of characters and replace the end with "..."
 *
 */
export const cutOffText = (input: string, maxCharacters: number): string => {
  return input.length > maxCharacters + 3 ? input.substring(0, maxCharacters).concat("...") : input;
};

/**
 * decodes html enities by creating a textarea, inserting the input and returning the value
 * (textarea does the decoding internally)
 *
 * source: https://stackoverflow.com/questions/44195322/a-plain-javascript-way-to-decode-html-entities-works-on-both-browsers-and-node
 */
export const decodeHtml = (input: string): string => {
  const translateRegex = /&(nbsp|amp|quot|lt|gt);/g;
  const translate = {
    nbsp: " ",
    amp: "&",
    quot: '"',
    lt: "<",
    gt: ">",
  };
  return input
    .replace(translateRegex, (_match, entity) => {
      return translate[entity];
    })
    .replace(/&#(\d+);/gi, (_match, numStr) => {
      const num = parseInt(numStr, 10);
      return String.fromCharCode(num);
    });
};

/**
 * variables for email en- and decryption
 */
const stoerer = "_sToErEr#";
const mailReplacement = "#_mAil~";
const dotReplacement = "-dOT_";

/**
 * encrypts an email address by placing an interfering element (Störer) after the first character
 * and replacing the @-symbol and the dot character before the domain ending with replacement sequences.
 *
 * This function is called when retrieving the plain text email addresses from the CMS.
 */
export const encryptMail = (input: string): string => {
  const lastDotIndex = input.lastIndexOf(".");
  const untilLastDot = input.slice(0, lastDotIndex);
  const fromLastDot = input.slice(lastDotIndex + 1, input.length);
  return `${untilLastDot.slice(0, 1)}${stoerer}${untilLastDot
    .slice(1)
    .replace("@", mailReplacement)}${dotReplacement}${fromLastDot}`;
};

/**
 * decrypts an email address formerly encrypted with the "encryptMail" function described above.
 *
 */
export const decryptMail = (input: string): string => {
  return input
    .replace(new RegExp(stoerer), "")
    .replace(new RegExp(mailReplacement), "@")
    .replace(new RegExp(dotReplacement), ".");
};

/**
 * creates the markup for use in React's dangerouslySetInnerHTML
 *
 * @param content the html content string
 */

export const createMarkup = (content: string) => {
  return {
    __html: content,
  };
};

/**
 * Removes the http(s):// part at the beginning and possibly a slash at the end of
 * a given url
 *
 * @param input a website url
 */
export const trimWebsiteUrl = (input: string): string => {
  return input.replace(/^https?:\/\//, "").replace(/\/?$/, "");
};

/**
 * gets the last slug of a url
 *
 * @param input a website url
 */
export const getLastSlug = (url: string): string => {
  const match = url.match(/\/[a-z-]+\/$/g);
  return match && match.length > 0 ? match[0] : null;
};

/**
 * Format UTCOffset
 *
 * @param utcOffset an UTC offset with "Z" moment.js format e.g. +01:00
 */
export const formatUTCOffsetText = (utcOffset: string): string => {
  const replaceColonWithDecimal = utcOffset.replace(":", ".");
  const convertToNumber = parseFloat(replaceColonWithDecimal);
  const numberValue = convertToNumber * 1;
  const hasPlusSymbol = numberValue >= 0 ? "+" : "";
  return `${hasPlusSymbol}${numberValue}`;
};
