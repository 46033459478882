import {
  UPDATE_ACTIVE_MATCHDAYS,
  UPDATE_ACTIVE_SYNERGIES,
  UPDATE_HOVERED_DAY,
  UPDATE_UTC_TIME_OFFSET,
  UPDATE_SELECTED_VIEW,
  ScheduleState,
  ScheduleActionTypes,
} from "./types";
import {utcTimeStates, scheduleViews} from "../../models";

const initialState: ScheduleState = {
  activeMatchdays: [2, 3], // friday + saturday as default (schedule week starting from wednesday)
  activeBroadcastSynergies: [],
  hoveredDay: null,
  selectedUTCTimeOffset: utcTimeStates.localTime,
  selectedView: scheduleViews.aroundMatchdays,
};

export function scheduleReducer(state = initialState, action: ScheduleActionTypes): ScheduleState {
  switch (action.type) {
    case UPDATE_ACTIVE_MATCHDAYS: {
      return {
        ...state,
        activeMatchdays: action.payload,
      };
    }

    case UPDATE_ACTIVE_SYNERGIES: {
      return {
        ...state,
        activeBroadcastSynergies: action.payload,
      };
    }

    case UPDATE_HOVERED_DAY: {
      return {
        ...state,
        hoveredDay: action.payload,
      };
    }

    case UPDATE_UTC_TIME_OFFSET: {
      return {
        ...state,
        selectedUTCTimeOffset: action.payload,
      };
    }

    case UPDATE_SELECTED_VIEW: {
      return {
        ...state,
        selectedView: action.payload,
      };
    }

    default:
      return state;
  }
}
