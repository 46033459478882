import * as React from "react";
import {
  colors,
  weekDays,
  broadcastProducts,
  broadcastProductMap,
  BroadcastProductType,
  ScheduleContentProps,
  BroadcastProductDescriptionProps,
  seasons,
  rundownBlockSizes,
  productLinks,
  broadcastProductLabels,
  rundownElementTypes,
  RundownContentProps,
  RundownSelectMenuValues,
} from "../models";
import {createMarkup} from "./util";

const getBroadcastProperties = (broadcastId: BroadcastProductType): BroadcastProductDescriptionProps => {
  const time = broadcastProductMap.get(broadcastId).time
    ? {
        anyTime: broadcastProductMap.get(broadcastId).time.anyTime,
        day: broadcastProductMap.get(broadcastId).time.day,
        hours: broadcastProductMap.get(broadcastId).time.hours,
        minutes: broadcastProductMap.get(broadcastId).time.minutes,
      }
    : null;
  return {
    id: broadcastId,
    time,
  };
};

export const scheduleContent: ScheduleContentProps = {
  aroundMatchdaysCategories: [
    {
      categoryName: "Bundesliga 2 Matches",
      days: [
        {
          weekDay: weekDays.wednesday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.thursday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.friday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_1830).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_1830).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_1830).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_1830)}],
            },
          ],
        },
        {
          weekDay: weekDays.saturday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SA_1300).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SA_1300).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SA_1300).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SA_1300)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SA_2030).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SA_2030).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SA_2030).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SA_2030)}],
            },
          ],
        },
        {
          weekDay: weekDays.sunday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SO_1330).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SO_1330).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SO_1330).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SO_1330)}],
            },
          ],
        },
        {
          weekDay: weekDays.monday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.tuesday,
          broadcastGroups: [],
        },
      ],
      backgroundColor: colors.redDFL,
      labelColor: colors.redDFL,
      textColor: colors.white,
    },

    {
      categoryName: "Bundesliga Matches",
      days: [
        {
          weekDay: weekDays.wednesday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.thursday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.friday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_2030)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_1945_IF).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_1945_IF).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_1945_IF).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_1945_IF)}],
            },
          ],
        },
        {
          weekDay: weekDays.saturday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SA_1530)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530_IF).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530_IF).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530_IF).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SA_1530_IF)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530_GoAr).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530_GoAr).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530_GoAr).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SA_1530_GoAr)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SA_1830).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SA_1830).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SA_1830).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SA_1830)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SA_1830_IF).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SA_1830_IF).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SA_1830_IF).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SA_1830_IF)}],
            },
          ],
        },
        {
          weekDay: weekDays.sunday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SU_1530).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SU_1530).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SU_1530).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SU_1530)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SU_1530_IF).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SU_1530_IF).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SU_1530_IF).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SU_1530_IF)},],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SU_1800).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SU_1800).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SU_1800).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SU_1800)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SU_1730_IF).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SU_1730_IF).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SU_1730_IF).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SU_1730_IF)},],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SU_1930).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SU_1930).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SU_1930).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SU_1930)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SU_1930_IF).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SU_1930_IF).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SU_1930_IF).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SU_1930_IF)}],
            },
          ],
        },
        {
          weekDay: weekDays.monday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.tuesday,
          broadcastGroups: [],
        },
      ],
      backgroundColor: colors.redDFL90,
      labelColor: colors.redDFL90,
      textColor: colors.white,
    },

    {
      categoryName: "Match Highlights",
      days: [
        {
          weekDay: weekDays.wednesday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.thursday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.friday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SMP).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SMP).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SMP).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_2030_SMP)}],
            },
          ],
        },
        {
          weekDay: weekDays.saturday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530_SMP).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530_SMP).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530_SMP).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SA_1530_SMP)},],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.HIGHLIGHTS_I).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.HIGHLIGHTS_I).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.HIGHLIGHTS_I).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.HIGHLIGHTS_I)}],
            },
          ],
        },
        {
          weekDay: weekDays.sunday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SU_1530_SMP).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SU_1530_SMP).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SU_1530_SMP).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SU_1530_SMP)},],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.HIGHLIGHTS_II).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.HIGHLIGHTS_II).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.HIGHLIGHTS_II).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.HIGHLIGHTS_II)}],
            },
          ],
        },
        {
          weekDay: weekDays.monday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.tuesday,
          broadcastGroups: [],
        },
      ],
      backgroundColor: colors.redDFL70,
      labelColor: colors.redDFL70,
      textColor: colors.white,
    },

    {
      categoryName: "Match Interviews",
      days: [
        {
          weekDay: weekDays.wednesday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.thursday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.friday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SFI).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SFI).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SFI).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_2030_SFI)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_PoMI).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_PoMI).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_PoMI).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_2030_PoMI)}],
            },
          ],
        },
        {
          weekDay: weekDays.saturday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530_SFI).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530_SFI).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530_SFI).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SA_1530_SFI)},],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530_PoMI).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530_PoMI).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SA_1530_PoMI).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SA_1530_PoMI)},],
            },
          ],
        },
        {
          weekDay: weekDays.sunday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SU_1530_SFI).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SU_1530_SFI).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SU_1530_SFI).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SU_1530_SFI)},],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_SU_1530_PoMI).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_SU_1530_PoMI).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_SU_1530_PoMI).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_SU_1530_PoMI)},],
            },
          ],
        },
        {
          weekDay: weekDays.monday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.tuesday,
          broadcastGroups: [],
        },
      ],
      backgroundColor: colors.redDFL30,
      labelColor: colors.redDFL30,
      textColor: colors.white,
    },

    {
      categoryName: "Weekly Content",
      days: [
        {
          weekDay: weekDays.wednesday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.DAILY_NEWS_WED).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.DAILY_NEWS_WED).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.DAILY_NEWS_WED).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.DAILY_NEWS_WED)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.BULI_WEEKLY).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.BULI_WEEKLY).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.BULI_WEEKLY).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.BULI_WEEKLY)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.MIDWEEK_INVU_WED).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.MIDWEEK_INVU_WED).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.MIDWEEK_INVU_WED).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.MIDWEEK_INVU_WED)}],
            },
          ],
        },
        {
          weekDay: weekDays.thursday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.DAILY_NEWS_THU).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.DAILY_NEWS_THU).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.DAILY_NEWS_THU).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.DAILY_NEWS_THU)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.MIDWEEK_INVU_THU).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.MIDWEEK_INVU_THU).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.MIDWEEK_INVU_THU).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.MIDWEEK_INVU_THU)}],
            },
          ],
        },
        {
          weekDay: weekDays.friday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.DAILY_NEWS_FRI).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.DAILY_NEWS_FRI).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.DAILY_NEWS_FRI).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.DAILY_NEWS_FRI)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.MIDWEEK_INVU_FRI).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.MIDWEEK_INVU_FRI).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.MIDWEEK_INVU_FRI).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.MIDWEEK_INVU_FRI)}],
            },
          ],
        },
        {
          weekDay: weekDays.saturday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.sunday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.monday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.WEEKLY_NEWSLETTER).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.WEEKLY_NEWSLETTER).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.WEEKLY_NEWSLETTER).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.WEEKLY_NEWSLETTER)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.DAILY_NEWS_MON).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.DAILY_NEWS_MON).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.DAILY_NEWS_MON).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.DAILY_NEWS_MON)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.MIDWEEK_INVU_MON).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.MIDWEEK_INVU_MON).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.MIDWEEK_INVU_MON).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.MIDWEEK_INVU_MON)}],
            },
          ],
        },
        {
          weekDay: weekDays.tuesday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.DAILY_NEWS_TUE).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.DAILY_NEWS_TUE).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.DAILY_NEWS_TUE).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.DAILY_NEWS_TUE)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.MIDWEEK_INVU_TUE).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.MIDWEEK_INVU_TUE).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.MIDWEEK_INVU_TUE).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.MIDWEEK_INVU_TUE)}],
            },
          ],
        },
      ],
      backgroundColor: colors.grayDark,
      labelColor: colors.grayDark,
      textColor: colors.white,
    },

    {
      categoryName: "Short Formats",
      days: [
        {
          weekDay: weekDays.wednesday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SCG).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SCG).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SCG).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_2030_SCG)}],
            },
          ],
        },
        {
          weekDay: weekDays.thursday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SCG).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SCG).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SCG).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_2030_SCG)}],
            },
          ],
        },
        {
          weekDay: weekDays.friday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_DHC).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_DHC).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_DHC).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_2030_DHC)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_NLC).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_NLC).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_NLC).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_2030_NLC)}],
            },
          ],
        },
        {
          weekDay: weekDays.saturday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_DHC).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_DHC).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_DHC).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_2030_DHC)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_NLC).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_NLC).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_NLC).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_2030_NLC)}],
            },
          ],
        },
        {
          weekDay: weekDays.sunday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_DHC).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_DHC).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_DHC).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_2030_DHC)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_NLC).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_NLC).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_NLC).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_2030_NLC)}],
            },
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SCG).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SCG).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SCG).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_2030_SCG)}],
            },
          ],
        },
        {
          weekDay: weekDays.monday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SCG).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SCG).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SCG).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_2030_SCG)}],
            },
          ],
        },
        {
          weekDay: weekDays.tuesday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SCG).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SCG).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.LIVE_FR_2030_SCG).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.LIVE_FR_2030_SCG)}],
            },
          ],
        },
      ],
      backgroundColor: colors.grayUltraLight,
      labelColor: colors.redDFL,
      textColor: colors.redDFL,
    },

    {
      categoryName: "Promo & Trailer",
      days: [
        {
          weekDay: weekDays.wednesday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.thursday,
          broadcastGroups: [
          ],
        },
        {
          weekDay: weekDays.friday,
          broadcastGroups: [
            {
              label: broadcastProductMap.get(broadcastProducts.TOPMATCH_PROMO_THU).scheduleLabel,
              subLabel: broadcastProductMap.get(broadcastProducts.TOPMATCH_PROMO_THU).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.TOPMATCH_PROMO_THU).productLink,
              broadcasts: [{...getBroadcastProperties(broadcastProducts.TOPMATCH_PROMO_THU)}],
            },],
        },
        {
          weekDay: weekDays.saturday,
          broadcastGroups: [
          ],
        },
        {
          weekDay: weekDays.sunday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.monday,
          broadcastGroups: [],
        },
        {
          weekDay: weekDays.tuesday,
          broadcastGroups: [],
        },
      ],
      backgroundColor: colors.grayLight,
      labelColor: colors.black,
      textColor: colors.black,
    },
  ],

  aroundSeasonCategories: [
    {
      categoryName: "Bundesliga Matches",
      backgroundColor: colors.redDFL,
      labelColor: colors.redDFL,
      textColor: colors.white,
      seasons: [
        {
          season: seasons.preSeason,
          seasonalProducts: [
            {
              label: broadcastProductMap.get(broadcastProducts.SUPERCUP_MATCH).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.SUPERCUP_MATCH).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.SUPERCUP_MATCH).productLink,
            },
          ],
        },
        {
          season: seasons.season,
          seasonalProducts: [
            {
              label: broadcastProductMap.get(broadcastProducts.UHD_MATCH).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.UHD_MATCH).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.UHD_MATCH).productLink,
            },
          ],
        },
        {
          season: seasons.postSeason,
          seasonalProducts: [
            {
              label: broadcastProductMap.get(broadcastProducts.RELEGATION_PLAYOFF_MATCH).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.RELEGATION_PLAYOFF_MATCH).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.RELEGATION_PLAYOFF_MATCH).productLink,
            },
          ],
        },
      ],
    },
    {
      categoryName: "Specials",
      backgroundColor: colors.grayMedium,
      labelColor: colors.grayMedium,
      textColor: colors.black,
      seasons: [
        {
          season: seasons.preSeason,
          seasonalProducts: [
            {
              label: broadcastProductMap.get(broadcastProducts.BULI_CLUBS).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.BULI_CLUBS).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.BULI_CLUBS).productLink,
            },
            {
              label: broadcastProductMap.get(broadcastProducts.SEASON_SPECIALS).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.SEASON_SPECIALS).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.SEASON_SPECIALS).productLink,
            },
          ],
        },
        {
          season: seasons.season,
          seasonalProducts: [
            {
              label: broadcastProductMap.get(broadcastProducts.BULI_SERIES).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.BULI_SERIES).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.BULI_SERIES).productLink,
            },
            {
              label: broadcastProductMap.get(broadcastProducts.MONTH_SPECIALS).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.MONTH_SPECIALS).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.MONTH_SPECIALS).productLink,
            },
            {
              label: broadcastProductMap.get(broadcastProducts.SEASON_SPECIALS).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.SEASON_SPECIALS).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.SEASON_SPECIALS).productLink,
            },
          ],
        },
        {
          season: seasons.postSeason,
          seasonalProducts: [
            {
              label: broadcastProductMap.get(broadcastProducts.SEASON_SPECIALS).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.SEASON_SPECIALS).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.SEASON_SPECIALS).productLink,
            },
          ],
        },
      ],
    },
    {
      categoryName: "Promotion & Trailers",
      backgroundColor: colors.grayUltraLight,
      labelColor: colors.redDFL,
      textColor: colors.redDFL,
      seasons: [
        {
          season: seasons.preSeason,
          seasonalProducts: [
            {
              label: broadcastProductMap.get(broadcastProducts.BULI_ENGAGEMENT_SUMMER).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.BULI_ENGAGEMENT_SUMMER).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.BULI_ENGAGEMENT_SUMMER).productLink,
            },
            {
              label: broadcastProductMap.get(broadcastProducts.SEASON_TRAILER).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.SEASON_TRAILER).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.SEASON_TRAILER).productLink,
            },
          ],
        },
        {
          season: seasons.season,
          seasonalProducts: [
            {
              label: broadcastProductMap.get(broadcastProducts.BULI_ENGAGEMENT_WINTER).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.BULI_ENGAGEMENT_WINTER).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.BULI_ENGAGEMENT_WINTER).productLink,
            },
            {
              label: broadcastProductMap.get(broadcastProducts.SEASON_TRAILER).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.SEASON_TRAILER).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.SEASON_TRAILER).productLink,
            },
          ],
        },
        {
          season: seasons.postSeason,
          seasonalProducts: [
          ],
        },
      ],
    },
    {
      categoryName: "Seasonal Content Toolkit",
      backgroundColor: colors.grayUltraLight,
      labelColor: colors.redDFL,
      textColor: colors.redDFL,
      seasons: [
        {
          season: seasons.preSeason,
          seasonalProducts: [
            {
              label: broadcastProductMap.get(broadcastProducts.MEDIA_KIT).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.MEDIA_KIT).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.MEDIA_KIT).productLink,
            },
            {
              label: broadcastProductMap.get(broadcastProducts.MEDIA_DAYS).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.MEDIA_DAYS).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.MEDIA_DAYS).productLink,
            },
          ],
        },
        {
          season: seasons.season,
          seasonalProducts: [
            {
              label: broadcastProductMap.get(broadcastProducts.TECH_SERVICES).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.TECH_SERVICES).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.TECH_SERVICES).productLink,
            },
          ],
        },
        {
          season: seasons.postSeason,
          seasonalProducts: [
            {
              label: broadcastProductMap.get(broadcastProducts.SUMMER_SPECIAL).scheduleLabel,
              description: broadcastProductMap.get(broadcastProducts.SUMMER_SPECIAL).scheduleSubLabel,
              productLink: broadcastProductMap.get(broadcastProducts.SUMMER_SPECIAL).productLink,
            },
          ],
        },
      ],
    },
  ],
};

export const rundownContent: RundownContentProps = {
  worldFeed: [
    {
      size: rundownBlockSizes.m,
      type: rundownElementTypes.block,
      label: "Pre-Match",
      description: "Fan and team arrivals, warm-up, enhanced stats, etc.",
      startTime: {
        day: weekDays.saturday,
        hours: null,
        minutes: null,
      },
      endTime: {
        day: weekDays.saturday,
        hours: null,
        minutes: null,
      },
      color: colors.redDFL70,
    },
    {
      type: rundownElementTypes.space,
      minutes: -10,
    },
    {
      size: rundownBlockSizes.l,
      type: rundownElementTypes.block,
      label: "Live Match",
      subLabel: "1st half",
      color: colors.redDFL,
      startTime: {
        day: weekDays.saturday,
        hours: null,
        minutes: null,
      },
      endTime: {
        day: weekDays.saturday,
        hours: null,
        minutes: null,
      },
    },
    {
      type: rundownElementTypes.space,
      minutes: 2,
    },
    {
      size: rundownBlockSizes.m,
      type: rundownElementTypes.block,
      label: "Half-Time",
      description: "Highlights, beauty shots, Compilations, enhanced stats, etc.",
      color: colors.redDFL70,
      link: {
        url: productLinks.nearLiveClips,
        label: broadcastProductLabels.nearLiveClips,
      },
    },
    {
      type: rundownElementTypes.space,
      minutes: -2,
    },
    {
      size: rundownBlockSizes.l,
      type: rundownElementTypes.block,
      label: "Live Match",
      subLabel: "2nd half",
      color: colors.redDFL,
      startTime: {
        day: weekDays.saturday,
        hours: null,
        minutes: null,
      },
      endTime: {
        day: weekDays.saturday,
        hours: null,
        minutes: null,
      },
    },
    {
      type: rundownElementTypes.space,
      minutes: 10,
    },
    {
      size: rundownBlockSizes.m,
      type: rundownElementTypes.block,
      label: "Post-Match",
      description: "Highlights, beauty shots, etc.",
      link: {
        url: productLinks.superFlashInvu,
        label: broadcastProductLabels.superFlashInvu,
      },
      color: colors.redDFL70,
      startTime: {
        day: weekDays.saturday,
        hours: null,
        minutes: null,
      },
      endTime: {
        day: weekDays.saturday,
        hours: null,
        minutes: null,
      },
      leftPosition: -34,
    },
  ],
  afterWorldFeed: [
    {
      type: rundownElementTypes.space,
    },
    {
      size: rundownBlockSizes.s,
      type: rundownElementTypes.block,
      link: {
        url: productLinks.digHighClips,
        label: broadcastProductLabels.digHighClips,
      },
      color: colors.redDFL,
      startTime: {
        day: weekDays.saturday,
        hours: 18,
        minutes: 30,
      },
      timePrefix: "Approx. ",
    },
    {
      type: rundownElementTypes.space,
    },
    {
      size: rundownBlockSizes.s,
      type: rundownElementTypes.block,
      link: {
        url: productLinks.sloMoPlayout,
        label: broadcastProductLabels.sloMoPlayout,
      },
      color: colors.redDFL,
      startTime: {
        day: weekDays.saturday,
        hours: 19,
        minutes: 30,
      },
      timePrefix: "Approx. ",
    },
    {
      type: rundownElementTypes.space,
    },
    {
      size: rundownBlockSizes.s,
      type: rundownElementTypes.block,
      link: {
        url: productLinks.postMatchInvu,
        label: broadcastProductLabels.postMatchInvu,
      },
      color: colors.redDFL70,
      startTime: {
        day: weekDays.saturday,
        hours: 20,
        minutes: 30,
      },
      timePrefix: "Approx. ",
    },
  ],
};

export const rundownDatesSelectOptionsNative = [
  {value: RundownSelectMenuValues.fridayNight, label: "Friday 20:30*"},
  {value: RundownSelectMenuValues.saturdayAfternoon, label: "Saturday 15:30*"},
  {value: RundownSelectMenuValues.saturdayNight, label: "Saturday 18:30*"},
  {value: RundownSelectMenuValues.sundayLunch, label: "Sunday 15:30*"},
  {value: RundownSelectMenuValues.sundayAfternoon, label: "Sunday 17:30*"},
  {value: RundownSelectMenuValues.sundayNight, label: "Sunday 19:30"},
  {value: RundownSelectMenuValues.tuesdayNight, label: "Tuesday 18:30"},
  {value: RundownSelectMenuValues.tuesdayLateNight, label: "Tuesday 20:30"},
  {value: RundownSelectMenuValues.wednesdayNight, label: "Wednesday 18:30"},
  {value: RundownSelectMenuValues.wednesdayLateNight, label: "Wednesday 20:30"},
];

export const rundownDatesSelectOptionsDesktop = [
  {
    value: RundownSelectMenuValues.fridayNight,
    label: <div dangerouslySetInnerHTML={createMarkup("<p>Friday</p> <p>20:30<span>*</span></p>")} />,
  },
  {
    value: RundownSelectMenuValues.saturdayAfternoon,
    label: <div dangerouslySetInnerHTML={createMarkup("<p>Saturday</p> <p>15:30<span>*</span></p>")} />,
  },
  {
    value: RundownSelectMenuValues.saturdayNight,
    label: <div dangerouslySetInnerHTML={createMarkup("<p>Saturday</p> <p>18:30<span>*</span></p>")} />,
  },
  {
    value: RundownSelectMenuValues.sundayLunch,
    label: <div dangerouslySetInnerHTML={createMarkup("<p>Sunday</p> <p>15:30<span>*</span></p>")} />,
  },
  {
    value: RundownSelectMenuValues.sundayAfternoon,
    label: <div dangerouslySetInnerHTML={createMarkup("<p>Sunday</p> <p>17:30<span>*</span></p>")} />,
  },
  {
    value: RundownSelectMenuValues.sundayNight,
    label: <div dangerouslySetInnerHTML={createMarkup("<p>Sunday</p> <p>19:30</p>")} />,
  },
  {
    value: RundownSelectMenuValues.tuesdayNight,
    label: <div dangerouslySetInnerHTML={createMarkup("<p>Tuesday</p> <p>18:30</p>")} />,
  },
  {
    value: RundownSelectMenuValues.tuesdayLateNight,
    label: <div dangerouslySetInnerHTML={createMarkup("<p>Tuesday</p> <p>20:30</p>")} />,
  },
  {
    value: RundownSelectMenuValues.wednesdayNight,
    label: <div dangerouslySetInnerHTML={createMarkup("<p>Wednesday</p> <p>18:30</p>")} />,
  },
  {
    value: RundownSelectMenuValues.wednesdayLateNight,
    label: <div dangerouslySetInnerHTML={createMarkup("<p>Wednesday</p> <p>20:30</p>")} />,
  },
];
