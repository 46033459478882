import {BroadcastProductType, UTCTimeStateType, ScheduleViewType} from "../../models";

export interface ScheduleState {
  activeMatchdays: [number, number];
  activeBroadcastSynergies: BroadcastProductType[];
  hoveredDay: number;
  selectedUTCTimeOffset: UTCTimeStateType;
  selectedView: ScheduleViewType;
}

// action descriptions
export const UPDATE_ACTIVE_MATCHDAYS = "UPDATE_ACTIVE_MATCHDAYS";
interface UpdateActiveMatchdays {
  type: typeof UPDATE_ACTIVE_MATCHDAYS;
  payload: [number, number];
}

export const UPDATE_ACTIVE_SYNERGIES = "UPDATE_ACTIVE_SYNERGIES";
interface UpdateActiveSynergies {
  type: typeof UPDATE_ACTIVE_SYNERGIES;
  payload: BroadcastProductType[];
}

export const UPDATE_HOVERED_DAY = "UPDATE_HOVERED_DAY";
interface UpdateHoveredDay {
  type: typeof UPDATE_HOVERED_DAY;
  payload: number;
}

export const UPDATE_UTC_TIME_OFFSET = "UPDATE_UTC_TIME_OFFSET";
interface UpdateUTCTimeOffset {
  type: typeof UPDATE_UTC_TIME_OFFSET;
  payload: UTCTimeStateType;
}

export const UPDATE_SELECTED_VIEW = "UPDATE_SELECTED_VIEW";
interface UpdateSelectedView {
  type: typeof UPDATE_SELECTED_VIEW;
  payload: ScheduleViewType;
}

export type ScheduleActionTypes =
  | UpdateActiveMatchdays
  | UpdateActiveSynergies
  | UpdateHoveredDay
  | UpdateUTCTimeOffset
  | UpdateSelectedView;
