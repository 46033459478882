import {dflRegions, navigationOverlayStates, currentJwPlayerId} from "../../models"; // fullPageSections,
import {
  UPDATE_SELECTED_REGION,
  SET_TIMEZONE_DETECTED,
  UPDATE_UTC_OFFSET,
  UPDATE_LOCAL_UTC_OFFSET_TEXT,
  UPDATE_NAVIGATION_OVERLAY,
  UPDATE_IS_NAVIGATION_HIDDEN,
  UPDATE_IS_NAVIGATION_INVERTED,
  UPDATE_IS_TEXTFILTER_ACTIVE,
  UPDATE_IS_HOMEPAGE_ABOVE_VIDEO,
  SET_CURRENT_JW_PLAYER_ID,
  SET_USER_DID_CONSENT,
  UPDATE_SCREENWIDTH,
  SystemState,
  SystemActionTypes,
} from "./types";

const initialState: SystemState = {
  selectedRegion: dflRegions.emea,
  timezoneDetected: false,
  utcOffset: 0,
  localUTCOffsetText: null,
  overlayState: navigationOverlayStates.default,
  isNavigationHidden: false,
  isNavigationInverted: true,
  isHomepageAboveVideo: true,
  isTextFilterActive: false,
  currentJwPlayerId,
  userDidConsent: true,
  screenWidth: 320,
};

export function systemReducer(state = initialState, action: SystemActionTypes): SystemState {
  switch (action.type) {
    case UPDATE_SELECTED_REGION: {
      return {
        ...state,
        selectedRegion: action.payload,
      };
    }

    case SET_TIMEZONE_DETECTED: {
      return {
        ...state,
        timezoneDetected: action.payload,
      };
    }

    case UPDATE_UTC_OFFSET: {
      return {
        ...state,
        utcOffset: action.payload,
      };
    }

    case UPDATE_LOCAL_UTC_OFFSET_TEXT: {
      return {
        ...state,
        localUTCOffsetText: action.payload,
      };
    }

    case UPDATE_NAVIGATION_OVERLAY: {
      return {
        ...state,
        overlayState: action.payload,
      };
    }

    case UPDATE_IS_NAVIGATION_HIDDEN: {
      return {
        ...state,
        isNavigationHidden: action.payload,
      };
    }

    case UPDATE_IS_NAVIGATION_INVERTED: {
      return {
        ...state,
        isNavigationInverted: action.payload,
      };
    }

    case UPDATE_IS_HOMEPAGE_ABOVE_VIDEO: {
      return {
        ...state,
        isHomepageAboveVideo: action.payload,
      };
    }

    case UPDATE_IS_TEXTFILTER_ACTIVE: {
      return {
        ...state,
        isTextFilterActive: action.payload,
      };
    }

    case SET_CURRENT_JW_PLAYER_ID: {
      return {
        ...state,
        currentJwPlayerId: action.payload,
      };
    }

    case SET_USER_DID_CONSENT: {
      return {
        ...state,
        userDidConsent: action.payload,
      };
    }

    case UPDATE_SCREENWIDTH: {
      return {
        ...state,
        screenWidth: action.payload,
      };
    }

    default:
      return state;
  }
}
