import {
  DflRegionType,
  dflRegions,
  tzDatabaseRegions,
  productCategories,
  ProductCategoryType,
  productCategorySlugs,
  ProductCategorySlugType,
  productCategoryTitles,
  ProductCategoryTitleType,
  productCategoryWordpressKeys,
  ProductCategoryWordpressKeyType,
  ProductWithDecorationType,
  productWithDecorations,
  ColorType,
  colors,
  WeekDayType,
  weekDays,
  BroadcastProductType,
  broadcastProducts,
  BroadcastTimeProps,
  UTCTimeStateType,
  utcTimeStates,
  SeasonType,
  seasons,
  ProductLinkType,
  productLinks,
  StaticPageType,
  staticPages,
  broadcastProductLabels,
  RundownSelectMenuValues,
} from "./constants";

export const dflRegionByString = new Map<string, DflRegionType>([
  // EMEA
  [tzDatabaseRegions.europe, dflRegions.emea],
  [tzDatabaseRegions.arctic, dflRegions.emea],
  [tzDatabaseRegions.africa, dflRegions.emea],
  [tzDatabaseRegions.antarctica, dflRegions.emea],
  [tzDatabaseRegions.atlantic, dflRegions.emea],

  // Americas
  [tzDatabaseRegions.america, dflRegions.americas],

  // Asia
  [tzDatabaseRegions.australia, dflRegions.asia],
  [tzDatabaseRegions.asia, dflRegions.asia],
  [tzDatabaseRegions.indian, dflRegions.asia],
  [tzDatabaseRegions.pacific, dflRegions.asia],
]);

interface CategoryMapProps {
  slug: ProductCategorySlugType;
  title: ProductCategoryTitleType;
  wpCategoryValue: ProductCategoryWordpressKeyType;
}
// maps category enums to category properties like slugs, titles
export const categoryMap = new Map<ProductCategoryType, CategoryMapProps>([
  [
    productCategories.bundesligaMatches,
    {
      slug: productCategorySlugs.bundesligaMatches,
      title: productCategoryTitles.bundesligaMatches,
      wpCategoryValue: productCategoryWordpressKeys.bundesligaMatches,
    },
  ],
  [
    productCategories.matchHighlights,
    {
      slug: productCategorySlugs.matchHighlights,
      title: productCategoryTitles.matchHighlights,
      wpCategoryValue: productCategoryWordpressKeys.matchHighlights,
    },
  ],
  [
    productCategories.matchInterviews,
    {
      slug: productCategorySlugs.matchInterviews,
      title: productCategoryTitles.matchInterviews,
      wpCategoryValue: productCategoryWordpressKeys.matchInterviews,
    },
  ],
  [
    productCategories.digitalContent,
    {
      slug: productCategorySlugs.digitalContent,
      title: productCategoryTitles.digitalContent,
      wpCategoryValue: productCategoryWordpressKeys.digitalContent,
    },
  ],
  [
    productCategories.weeklyContent,
    {
      slug: productCategorySlugs.weeklyContent,
      title: productCategoryTitles.weeklyContent,
      wpCategoryValue: productCategoryWordpressKeys.weeklyContent,
    },
  ],
  [
    productCategories.monthlyContent,
    {
      slug: productCategorySlugs.monthlyContent,
      title: productCategoryTitles.monthlyContent,
      wpCategoryValue: productCategoryWordpressKeys.monthlyContent,
    },
  ],
  [
    productCategories.seasonalContent,
    {
      slug: productCategorySlugs.seasonalContent,
      title: productCategoryTitles.seasonalContent,
      wpCategoryValue: productCategoryWordpressKeys.seasonalContent,
    },
  ],
  [
    productCategories.promoTrailerContent,
    {
      slug: productCategorySlugs.promoTrailerContent,
      title: productCategoryTitles.promoTrailerContent,
      wpCategoryValue: productCategoryWordpressKeys.promoTrailerContent,
    },
  ],
]);

// maps wordpress category keys to category slugs
export const categoryWordpressKeyMap = new Map<ProductCategoryWordpressKeyType, ProductCategorySlugType>([
  [productCategoryWordpressKeys.bundesligaMatches, productCategorySlugs.bundesligaMatches],
  [productCategoryWordpressKeys.matchHighlights, productCategorySlugs.matchHighlights],
  [productCategoryWordpressKeys.matchInterviews, productCategorySlugs.matchInterviews],
  [productCategoryWordpressKeys.digitalContent, productCategorySlugs.digitalContent],
  [productCategoryWordpressKeys.promoTrailerContent, productCategorySlugs.promoTrailerContent],
  [productCategoryWordpressKeys.weeklyContent, productCategorySlugs.weeklyContent],
  [productCategoryWordpressKeys.monthlyContent, productCategorySlugs.monthlyContent],
  [productCategoryWordpressKeys.seasonalContent, productCategorySlugs.seasonalContent],
]);

interface StaticPageMapProps {
  slug: string;
  label: string;
}
export const staticPageMap = new Map<StaticPageType, StaticPageMapProps>([
  [staticPages.contact, {slug: "contact", label: "Contact"}],
  [staticPages.whatsNew, {slug: "whats-new", label: "What's new"}],
  [staticPages.legalNotice, {slug: "legal-notices", label: "Legal Notices"}],
  [staticPages.privacyPolicy, {slug: "privacy-policy", label: "Privacy Statement"}],
  [staticPages.imprint, {slug: "imprint", label: "Imprint"}],
]);

interface DecorationMapProps {
  label: string;
  color: ColorType;
}
export const decorationMap = new Map<ProductWithDecorationType, DecorationMapProps>([
  [productWithDecorations.new, {label: "NEW!", color: colors.redDFL}],
  [productWithDecorations.enhancement, {label: "ENHANCEMENT", color: colors.grayDark}],
  [productWithDecorations.none, {label: "", color: colors.transparent}],
]);

interface WeekDayMapProps {
  shortName: string;
  fullName: string;
}
export const weekDayMap = new Map<WeekDayType, WeekDayMapProps>([
  [weekDays.monday, {shortName: "Mon", fullName: "Monday"}],
  [weekDays.tuesday, {shortName: "Tue", fullName: "Tuesday"}],
  [weekDays.wednesday, {shortName: "Wed", fullName: "Wednesday"}],
  [weekDays.thursday, {shortName: "Thu", fullName: "Thursday"}],
  [weekDays.friday, {shortName: "Fri", fullName: "Friday"}],
  [weekDays.saturday, {shortName: "Sat", fullName: "Saturday"}],
  [weekDays.sunday, {shortName: "Sun", fullName: "Sunday"}],
]);

interface SeasonMapProps {
  name: string;
}
export const seasonMap = new Map<SeasonType, SeasonMapProps>([
  [seasons.preSeason, {name: "Pre-Season"}],
  [seasons.season, {name: "Season"}],
  [seasons.postSeason, {name: "Post-Season"}],
]);

interface BroadcastProductMapProps {
  scheduleLabel: string;
  scheduleSubLabel?: string;
  time?: BroadcastTimeProps | {anyTime:boolean};
  enhancement?: {
    label: string;
    color: ColorType;
  };
  productLink: ProductLinkType;
}
export const broadcastProductMap = new Map<BroadcastProductType, BroadcastProductMapProps>([
  // friday 20:30
  [
    broadcastProducts.LIVE_FR_1830,
    {
      time: {
        anyTime: false,
        day: weekDays.friday,
        hours: 18,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.liveMatch,
      scheduleSubLabel: "2 Matches",
      productLink: productLinks.liveMatch,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1300,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 13,
        minutes: 0,
      },
      scheduleLabel: broadcastProductLabels.liveMatch,
      scheduleSubLabel: "3 Matches",
      productLink: productLinks.liveMatch,
    },
  ],
  [
    broadcastProducts.LIVE_SA_2030,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 20,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.liveMatch,
      scheduleSubLabel: "1 Match",
      productLink: productLinks.liveMatch,
    },
  ],
  [
    broadcastProducts.LIVE_SO_1330,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 13,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.liveMatch,
      scheduleSubLabel: "3 Match",
      productLink: productLinks.liveMatch,
    },
  ],
  [
    broadcastProducts.LIVE_FR_2030,
    {
      time: {
        anyTime: false,
        day: weekDays.friday,
        hours: 20,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.liveMatch,
      scheduleSubLabel: "1 Match",
      productLink: productLinks.liveMatch,
    },
  ],
  [
    broadcastProducts.LIVE_FR_2030_SMP,
    {
      scheduleLabel: broadcastProductLabels.sloMoPlayout,
      productLink: productLinks.sloMoPlayout,
    },
  ],
  [
    broadcastProducts.LIVE_FR_2030_DHC,
    {
      scheduleLabel: broadcastProductLabels.digHighClips,
      productLink: productLinks.digHighClips,
    },
  ],
  [
    broadcastProducts.LIVE_FR_2030_NLC,
    {
      scheduleLabel: broadcastProductLabels.nearLiveClips,
      productLink: productLinks.nearLiveClips,
    },
  ],
  [
    broadcastProducts.LIVE_FR_2030_SCG,
    {
      scheduleLabel: broadcastProductLabels.shortClipsGraphics,
      productLink: productLinks.shortClipsGraphics,
    },
  ],
  [
    broadcastProducts.LIVE_FR_2030_PreMI,
    {
      time: {
        anyTime: false,
        day: weekDays.friday,
        hours: 19,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.preMatchInvu,
      productLink: productLinks.preMatchInvu,
    },
  ],
  [
    broadcastProducts.LIVE_FR_2030_SFI,
    {
      scheduleLabel: broadcastProductLabels.superFlashInvu,
      productLink: productLinks.superFlashInvu,
    },
  ],
  [
    broadcastProducts.LIVE_FR_2030_PoMI,
    {
      scheduleLabel: broadcastProductLabels.postMatchInvu,
      productLink: productLinks.postMatchInvu,
    },
  ],

  // saturday 15:30
  [
    broadcastProducts.LIVE_SA_1530,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 15,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.liveMatch,
      scheduleSubLabel: "5 Matches",
      productLink: productLinks.liveMatch,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1530_IHF,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 15,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.instantHighFeed,
      productLink: productLinks.instantHighFeed,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1530_SMP,
    {
      scheduleLabel: broadcastProductLabels.sloMoPlayout,
      productLink: productLinks.sloMoPlayout,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1530_DHC,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 18,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.digHighClips,
      productLink: productLinks.digHighClips,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1530_PreMI,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 14,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.preMatchInvu,
      productLink: productLinks.preMatchInvu,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1530_SFI,
    {
      scheduleLabel: broadcastProductLabels.superFlashInvu,
      productLink: productLinks.superFlashInvu,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1530_PoMI,
    {
      scheduleLabel: broadcastProductLabels.postMatchInvu,
      productLink: productLinks.postMatchInvu,
    },
  ],

  // saturday 18:30
  [
    broadcastProducts.LIVE_SA_1830,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 18,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.liveMatch,
      scheduleSubLabel: "1 Match",
      productLink: productLinks.liveMatch,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1830_SMP,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 22,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.sloMoPlayout,
      productLink: productLinks.sloMoPlayout,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1830_DHC,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 21,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.digHighClips,
      productLink: productLinks.digHighClips,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1830_PreMI,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 17,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.digHighClips,
      productLink: productLinks.digHighClips,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1830_SFI,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 20,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.superFlashInvu,
      productLink: productLinks.superFlashInvu,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1830_PoMI,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 23,
        minutes: 30,
      },
      scheduleLabel: "Live Match",
      productLink: productLinks.postMatchInvu,
    },
  ],

  // sunday 15:30
  [
    broadcastProducts.LIVE_SU_1530,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 15,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.liveMatch,
      productLink: productLinks.liveMatch,
    },
  ],
  [
    broadcastProducts.LIVE_SU_1530_SMP,
    {
      scheduleLabel: broadcastProductLabels.sloMoPlayout,
      productLink: productLinks.sloMoPlayout,
    },
  ],
  [
    broadcastProducts.LIVE_SU_1530_DHC,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 18,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.digHighClips,
      productLink: productLinks.digHighClips,
    },
  ],
  [
    broadcastProducts.LIVE_SU_1530_PreMI,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 14,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.preMatchInvu,
      productLink: productLinks.preMatchInvu,
    },
  ],
  [
    broadcastProducts.LIVE_SU_1530_SFI,
    {
      scheduleLabel: broadcastProductLabels.superFlashInvu,
      productLink: productLinks.superFlashInvu,
    },
  ],
  [
    broadcastProducts.LIVE_SU_1530_PoMI,
    {
      scheduleLabel: broadcastProductLabels.postMatchInvu,
      productLink: productLinks.postMatchInvu,
    },
  ],

  // sunday 18:00
  [
    broadcastProducts.LIVE_SU_1800,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 17,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.liveMatch,
      productLink: productLinks.liveMatch,
    },
  ],
  [
    broadcastProducts.LIVE_SU_1800_SMP,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 22,
        minutes: 0,
      },
      scheduleLabel: broadcastProductLabels.sloMoPlayout,
      productLink: productLinks.sloMoPlayout,
    },
  ],
  [
    broadcastProducts.LIVE_SU_1800_DHC,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 20,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.digHighClips,
      productLink: productLinks.digHighClips,
    },
  ],
  [
    broadcastProducts.LIVE_SU_1800_PreMI,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 16,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.preMatchInvu,
      productLink: productLinks.preMatchInvu,
    },
  ],
  [
    broadcastProducts.LIVE_SU_1800_SFI,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 20,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.superFlashInvu,
      productLink: productLinks.superFlashInvu,
    },
  ],
  [
    broadcastProducts.LIVE_SU_1800_PoMI,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 22,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.postMatchInvu,
      productLink: productLinks.postMatchInvu,
    },
  ],

  // sunday 19:30
  [
    broadcastProducts.LIVE_SU_1930,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 19,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.liveMatch,
      scheduleSubLabel: "10 x per season",
      productLink: productLinks.liveMatch,
    },
  ],

  // highlight shows
  [
    broadcastProducts.HIGHLIGHTS_I,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 23,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.highlightShow1,
      productLink: productLinks.highLightsShow1,
    },
  ],
  [
    broadcastProducts.HIGHLIGHTS_II,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 21,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.highlightShow2,
      productLink: productLinks.highLightsShow2,
    },
  ],

  // roundup clips
  [
    broadcastProducts.ROUNDUP_CLIPS_I,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 22,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.roundupClips1,
      productLink: productLinks.roundupClips,
    },
  ],
  [
    broadcastProducts.ROUNDUP_CLIPS_II,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 22,
        minutes: 0,
      },
      scheduleLabel: broadcastProductLabels.roundupClips2,
      productLink: productLinks.roundupClips,
    },
  ],
  [
    broadcastProducts.MATCHDAY_CLIPS,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 23,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.matchdayClips,
      productLink: productLinks.matchdayClips,
    },
  ],

  // daily news feed
  [
    broadcastProducts.DAILY_NEWS_MON,
    {
      time: {
        anyTime: false,
        day: weekDays.monday,
        hours: 21,
        minutes: 0,
      },
      scheduleLabel: broadcastProductLabels.dailyNewsfeed,
      productLink: productLinks.dailyNewsfeed,
    },
  ],
  [
    broadcastProducts.DAILY_NEWS_TUE,
    {
      time: {
        anyTime: false,
        day: weekDays.tuesday,
        hours: 21,
        minutes: 0,
      },
      scheduleLabel: broadcastProductLabels.dailyNewsfeed,
      productLink: productLinks.dailyNewsfeed,
    },
  ],
  [
    broadcastProducts.DAILY_NEWS_WED,
    {
      time: {
        anyTime: false,
        day: weekDays.wednesday,
        hours: 21,
        minutes: 0,
      },
      scheduleLabel: broadcastProductLabels.dailyNewsfeed,
      productLink: productLinks.dailyNewsfeed,
    },
  ],
  [
    broadcastProducts.DAILY_NEWS_THU,
    {
      time: {
        anyTime: false,
        day: weekDays.thursday,
        hours: 21,
        minutes: 0,
      },
      scheduleLabel: broadcastProductLabels.dailyNewsfeed,
      productLink: productLinks.dailyNewsfeed,
    },
  ],
  [
    broadcastProducts.DAILY_NEWS_FRI,
    {
      time: {
        anyTime: false,
        day: weekDays.friday,
        hours: 21,
        minutes: 0,
      },
      scheduleLabel: broadcastProductLabels.dailyNewsfeed,
      productLink: productLinks.dailyNewsfeed,
    },
  ],

  // midweek interviews
  [
    broadcastProducts.MIDWEEK_INVU_MON,
    {
      scheduleLabel: broadcastProductLabels.midweekInvus,
      productLink: productLinks.midweekInvus,
    },
  ],
  [
    broadcastProducts.MIDWEEK_INVU_TUE,
    {
      scheduleLabel: broadcastProductLabels.midweekInvus,
      productLink: productLinks.midweekInvus,
    },
  ],
  [
    broadcastProducts.MIDWEEK_INVU_WED,
    {
      scheduleLabel: broadcastProductLabels.midweekInvus,
      productLink: productLinks.midweekInvus,
    },
  ],
  [
    broadcastProducts.MIDWEEK_INVU_THU,
    {
      scheduleLabel: broadcastProductLabels.midweekInvus,
      productLink: productLinks.midweekInvus,
    },
  ],
  [
    broadcastProducts.MIDWEEK_INVU_FRI,
    {
      scheduleLabel: broadcastProductLabels.midweekInvus,
      productLink: productLinks.midweekInvus,
    },
  ],

  // top match promos
  [
    broadcastProducts.TOPMATCH_PROMO_FRI,
    {
      scheduleLabel: broadcastProductLabels.topMatchPromo,
      productLink: productLinks.topMatchPromo,
    },
  ],
  [
    broadcastProducts.TOPMATCH_PROMO_SAT,
    {
      scheduleLabel: broadcastProductLabels.topMatchPromo,
      productLink: productLinks.topMatchPromo,
    },
  ],
  [
    broadcastProducts.TOPMATCH_PROMO_SUN,
    {
      scheduleLabel: broadcastProductLabels.topMatchPromo,
      productLink: productLinks.topMatchPromo,
    },
  ],

  [
    broadcastProducts.BULI_WEEKLY,
    {
      time: {
        anyTime: false,
        day: weekDays.wednesday,
        hours: 23,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.buliWeekly,
      productLink: productLinks.buliWeekly,
    },
  ],
  [
    broadcastProducts.BULI_WEEKLY_LOCALISED,
    {
      time: {
        anyTime: false,
        day: weekDays.wednesday,
        hours: 23,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.buliWeeklyLocalised,
      productLink: productLinks.buliWeeklyLocalised,
    },
  ],
  [
    broadcastProducts.CONF_FEED_THU,
    {
      time: {
        anyTime: false,
        day: weekDays.thursday,
        hours: 21,
        minutes: 0,
      },
      scheduleLabel: broadcastProductLabels.confFeed,
      productLink: productLinks.confFeed,
    },
  ],
  [
    broadcastProducts.CONF_FEED_FRI,
    {
      time: {
        anyTime: false,
        day: weekDays.friday,
        hours: 21,
        minutes: 0,
      },
      scheduleLabel: broadcastProductLabels.confFeed,
      productLink: productLinks.confFeed,
    },
  ],
  [
    broadcastProducts.BEST_OF_MONTH,
    {
      time: {
        anyTime: false,
        day: weekDays.tuesday,
        hours: 23,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.bestOfMonth,
      scheduleSubLabel: "Monthly show of highlights",
      productLink: productLinks.bestOfMonth,
    },
  ],
  [
    broadcastProducts.INVU_OF_MONTH,
    {
      time: {
        anyTime: false,
        day: weekDays.tuesday,
        hours: 23,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.invuOfMonth,
      scheduleSubLabel: "Patrick Owowmoyela meets Bundesliga stars ",
      productLink: productLinks.invuOfMonth,
      enhancement: {
        label: "New",
        color: colors.redDFL,
      },
    },
  ],
  [
    broadcastProducts.BULI_REPORT,
    {
      time: {
        anyTime: false,
        day: weekDays.tuesday,
        hours: 23,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.buliReport,
      scheduleSubLabel: "Stars, stories and behind the scenes",
      productLink: productLinks.buliReport,
    },
  ],
  [
    broadcastProducts.BULI_CLUBS,
    {
      scheduleLabel: broadcastProductLabels.buliClubs,
      scheduleSubLabel: "Edutainment and fascinating stories for each Bundesliga Club",
      productLink: productLinks.buliClubs,
    },
  ],
  [
    broadcastProducts.SEASON_SPECIALS,
    {
      scheduleLabel: broadcastProductLabels.seasonSpecials,
      scheduleSubLabel: "Show formats during the season breaks",
      productLink: productLinks.seasonSpecials,
    },
  ],
  [
    broadcastProducts.MONTH_SPECIALS,
    {
      scheduleLabel: broadcastProductLabels.monthSpecials,
      scheduleSubLabel: "Editorial shows featuring stars, behind-the-scenes stories and highlights from past month",
      productLink: productLinks.monthSpecials,
    },
  ],
  [
    broadcastProducts.BULI_SERIES,
    {
      scheduleLabel: broadcastProductLabels.buliSeries,
      scheduleSubLabel: "Bundesliga storytelling with multi-part-episodes",
      productLink: productLinks.buliSeries,
    },
  ],
  [
    broadcastProducts.SEASON_TRAILER,
    {
      scheduleLabel: broadcastProductLabels.seasonTrailer,
      scheduleSubLabel: "Longlasting season trailer",
      productLink: productLinks.seasonTrailer,
    },
  ],
  [
    broadcastProducts.BULI_ANALYSIS,
    {
      scheduleLabel: broadcastProductLabels.buliAnalysis,
      scheduleSubLabel: "Data-driven story format with Bundesliga Match Facts",
      productLink: productLinks.buliAnalysis,
    },
  ],
  [
    broadcastProducts.SUMMER_SPECIAL,
    {
      time: {
        anyTime: false,
        day: weekDays.tuesday,
        hours: 23,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.summerSpecial,
      scheduleSubLabel: "Top Goals and Secret Facts from the last season",
      productLink: productLinks.summerSpecial,
    },
  ],
  [
    broadcastProducts.SEASAON_TICKET_PRE,
    {
      time: {
        anyTime: false,
        day: weekDays.tuesday,
        hours: 23,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.seasonTicket,
      scheduleSubLabel: "Preview show",
      productLink: productLinks.seasonTicket,
    },
  ],
  [
    broadcastProducts.SEASAON_TICKET_MID,
    {
      time: {
        anyTime: false,
        day: weekDays.tuesday,
        hours: 23,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.seasonTicket,
      scheduleSubLabel: "Midseason review",
      productLink: productLinks.seasonTicket,
    },
  ],
  [
    broadcastProducts.SEASAON_TICKET_POST,
    {
      time: {
        anyTime: false,
        day: weekDays.tuesday,
        hours: 23,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.seasonTicket,
      scheduleSubLabel: "Review of the last season",
      productLink: productLinks.seasonTicket,
    },
  ],

  // social media
  [
    broadcastProducts.SOCMED_CONTENT_SERVICE_MON,
    {
      scheduleLabel: broadcastProductLabels.socMedContentService,
      productLink: productLinks.socMedContentService,
    },
  ],
  [
    broadcastProducts.SOCMED_CONTENT_SERVICE_TUE,
    {
      scheduleLabel: broadcastProductLabels.socMedContentService,
      productLink: productLinks.socMedContentService,
    },
  ],
  [
    broadcastProducts.SOCMED_CONTENT_SERVICE_WED,
    {
      scheduleLabel: broadcastProductLabels.socMedContentService,
      productLink: productLinks.socMedContentService,
    },
  ],
  [
    broadcastProducts.SOCMED_CONTENT_SERVICE_THU,
    {
      scheduleLabel: broadcastProductLabels.socMedContentService,
      productLink: productLinks.socMedContentService,
    },
  ],

  [
    broadcastProducts.WEEKLY_NEWSLETTER,
    {
      time: {
        anyTime: false,
        day: weekDays.monday,
        hours: 11,
        minutes: 0,
      },
      scheduleLabel: broadcastProductLabels.weeklyNews,
      productLink: productLinks.weeklyNews,
    },
  ],

  [
    broadcastProducts.SUPERCUP_MATCH,
    {
      scheduleLabel: broadcastProductLabels.supercupMatch,
      scheduleSubLabel: "Bundesliga Champion vs. DFB-Cup Winner",
      productLink: productLinks.supercupMatch,
    },
  ],
  [
    broadcastProducts.RELEGATION_PLAYOFF_MATCH,
    {
      scheduleLabel: broadcastProductLabels.relegationPlayoff,
      scheduleSubLabel: "Bundesliga vs. Bundesliga 2 (two matches)",
      productLink: productLinks.relegationPlayoff,
    },
  ],
  [
    broadcastProducts.UHD_MATCH,
    {
      scheduleLabel: broadcastProductLabels.uhdMatch,
      scheduleSubLabel: "Ratecard service in UHD-SDR production",
      productLink: productLinks.uhdMatch,
    },
  ],
  [
    broadcastProducts.FAIMTB_TRAILER,
    {
      scheduleLabel: broadcastProductLabels.faimtbTrailer,
      scheduleSubLabel: "What Bundesliga stands for",
      productLink: productLinks.faimtbTrailer,
    },
  ],
  [
    broadcastProducts.SEASAON_TRAILER,
    {
      scheduleLabel: broadcastProductLabels.seasonTrailer,
      scheduleSubLabel: "Longlasting season trailer",
      productLink: productLinks.seasonTrailer,
    },
  ],
  [
    broadcastProducts.PROMO_TRAILER,
    {
      scheduleLabel: broadcastProductLabels.promoTrailer,
      scheduleSubLabel: "Footage from each club for your productions",
      productLink: productLinks.promoTrailer,
    },
  ],
  [
    broadcastProducts.BULI_ENGAGEMENT_WINTER,
    {
      scheduleLabel: broadcastProductLabels.buliEngagement,
      scheduleSubLabel: "Released during the winter break",
      productLink: productLinks.buliEngagement,
    },
  ],
  [
    broadcastProducts.MEDIA_KIT,
    {
      scheduleLabel: broadcastProductLabels.mediaKit,
      scheduleSubLabel: "Bundesliga brand elements and the best trailer footage per club",
      productLink: productLinks.mediaKit,
    },
  ],
  [
    broadcastProducts.MEDIA_DAYS,
    {
      scheduleLabel: broadcastProductLabels.mediaDays,
      scheduleSubLabel: "Individualised player stand-ups and interviews to promote all channels",
      productLink: productLinks.mediaDays,
    },
  ],
  [
    broadcastProducts.TECH_SERVICES,
    {
      scheduleLabel: broadcastProductLabels.techServices,
      scheduleSubLabel: "Digital Bundesliga fixtures, Clip Rule Creator, Show Thumbnails and many more",
      productLink: productLinks.techServices,
    },
  ],
  [
    broadcastProducts.BULI_ENGAGEMENT_SUMMER,
    {
      scheduleLabel: broadcastProductLabels.buliEngagement,
      scheduleSubLabel: "Released during the summer break",
      productLink: productLinks.buliEngagement,
    },
  ],
  [
    broadcastProducts.BULI_PLATFORM_SYNERGY,
    {
      scheduleLabel: broadcastProductLabels.platformSynergy,
      scheduleSubLabel: "Highlights from official Bundesliga channels",
      productLink: productLinks.platformSynergy,
    },
  ],
  [
    broadcastProducts.LIVE_FR_1945_IF,
    {
      time: {
        anyTime: false,
        day: weekDays.friday,
        hours: 20,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.interactiveFeed,
      productLink: productLinks.whatsNew,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1530_IF,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 15,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.interactiveFeed,
      productLink: productLinks.whatsNew,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1530_GoAr,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 15,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.goalArena,
      productLink: productLinks.goalArena,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1830_IF,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 18,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.interactiveFeed,
      productLink: productLinks.liveMatch,
    },
  ],
  [
    broadcastProducts.LIVE_FR_1830_SMMF,
    {
        time: {
          anyTime: false,
          day: weekDays.friday,
          hours: 18,
          minutes: 30,
        },
      scheduleLabel: broadcastProductLabels.socialMediaMatchdayFeed,
      productLink: productLinks.socialMediaMatchdayFeed,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1330_SMMF,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 13,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.socialMediaMatchdayFeed,
      productLink: productLinks.socialMediaMatchdayFeed,
    },
  ],
  [
    broadcastProducts.LIVE_SA_1630_SMMF,
    {
      time: {
        anyTime: false,
        day: weekDays.saturday,
        hours: 16,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.socialMediaMatchdayFeed,
      productLink: productLinks.socialMediaMatchdayFeed,
    },
  ],
  [
    broadcastProducts.LIVE_SU_1530_IF,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 15,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.interactiveFeed,
      productLink: productLinks.whatsNew,
    },
  ],
  [
    broadcastProducts.LIVE_SU_1730_IF,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 17,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.interactiveFeed,
      productLink: productLinks.whatsNew,
    },
  ],
  [
    broadcastProducts.LIVE_SU_1930_IF,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 19,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.interactiveFeed,
      productLink: productLinks.whatsNew,
    },
  ],
  [
    broadcastProducts.LIVE_SU_1330_SMMF,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 13,
        minutes: 30,
      },
      scheduleLabel: broadcastProductLabels.socialMediaMatchdayFeed,
      productLink: productLinks.socialMediaMatchdayFeed,
    },
  ],
  [
    broadcastProducts.LIVE_SU_1600_SMMF,
    {
      time: {
        anyTime: false,
        day: weekDays.sunday,
        hours: 16,
        minutes: 0,
      },
      scheduleLabel: broadcastProductLabels.socialMediaMatchdayFeed,
      productLink: productLinks.socialMediaMatchdayFeed,
    },
  ],
  [
    broadcastProducts.LIVE_MO_1000_CC,
    {
      time: {
        anyTime: false,
        day: weekDays.monday,
        hours: 10,
        minutes: 0,
      },
      scheduleLabel: broadcastProductLabels.customisedClips,
      productLink: productLinks.liveMatch,
    },
  ],
  [
    broadcastProducts.SEASON_CUSTOMISED_CLIPS_MID,
    {
      scheduleLabel: broadcastProductLabels.customisedClips,
      scheduleSubLabel: "Artificial Intelligence driven customisable highlights clips",
      productLink: productLinks.liveMatch,
    },
  ],
  [
    broadcastProducts.SEASON_BEST_OF_MONTH_MID,
    {
      scheduleLabel: broadcastProductLabels.bestOfMonth,
      scheduleSubLabel: "Reviewing past month´s top stories",
      productLink: productLinks.bestOfMonth,
    },
  ],
  [
    broadcastProducts.SEASON_BEST_OF_MONTH_LOCALISED_MID,
    {
      scheduleLabel: broadcastProductLabels.bestOfTheMonthLocalisedElements,
      scheduleSubLabel: "Editorial content pieces targeted to different regions",
      productLink: productLinks.localisedStorytellingElementsBestOfTheMonth,
    },
  ],
  [
    broadcastProducts.SEASON_TICKET_LOCALISED_PRE,
    {
      scheduleLabel: broadcastProductLabels.seasonTicketLocalisedElements,
      scheduleSubLabel: "Editorial content pieces targeted to different regions",
      productLink: productLinks.localisedStorytellingElementsSeasonTicket,
    },
  ],
  [
    broadcastProducts.SEASON_TICKET_LOCALISED_MID,
    {
      scheduleLabel: broadcastProductLabels.seasonTicketLocalisedElements,
      scheduleSubLabel: "Editorial content pieces targeted to different regions",
      productLink: productLinks.localisedStorytellingElementsSeasonTicket,
    },
  ],
  [
    broadcastProducts.SEASON_TICKET_LOCALISED_POST,
    {
      scheduleLabel: broadcastProductLabels.seasonTicketLocalisedElements,
      scheduleSubLabel: "Editorial content pieces targeted to different regions",
      productLink: productLinks.localisedStorytellingElementsSeasonTicket,
    },
  ],
  [
    broadcastProducts.CUSTOMISED_CLIPS_FR,
    {
      time: {
        anyTime: true,
      },
      scheduleLabel: broadcastProductLabels.customisedClips,
      productLink: productLinks.whatsNew,
    },
  ],
  [
    broadcastProducts.CUSTOMISED_CLIPS_SA,
    {
      time: {
        anyTime: true,
      },
      scheduleLabel: broadcastProductLabels.customisedClips,
      productLink: productLinks.whatsNew,
    },
  ],
  [
    broadcastProducts.CUSTOMISED_CLIPS_SO,
    {
      time: {
        anyTime: true,
      },
      scheduleLabel: broadcastProductLabels.customisedClips,
      productLink: productLinks.whatsNew,
    },
  ],
  [
    broadcastProducts.TOPMATCH_PROMO_THU,
    {
      time: {
        anyTime: false,
        day: weekDays.thursday,
        hours: 20,
        minutes: 0,
      },
      scheduleLabel: broadcastProductLabels.topMatchPromoNetxtMatchday,
      productLink: productLinks.topMatchPromo,
    },
  ],
]);

export const utcTimeStateMap = new Map<UTCTimeStateType, string>([
  [utcTimeStates.germanTime, "CET/CEST"],
  [utcTimeStates.localTime, "Local time"],
]);

export const rundownTimeMap = new Map([
  [
    RundownSelectMenuValues.fridayNight,
    {
      preMatchStart: {
        hours: 19,
        minutes: 30,
      },
      preMatchEnd: {
        hours: 20,
        minutes: 20,
      },
      liveMatchFirstHalfStart: {
        hours: 20,
        minutes: 30,
      },
      liveMatchFirstHalfEnd: {
        hours: 21,
        minutes: 16,
      },
      liveMatchSecondHalfStart: {
        hours: 21,
        minutes: 33,
      },
      liveMatchSecondHalfEnd: {
        hours: 22,
        minutes: 18,
      },
      postMatchStart: {
        hours: 22,
        minutes: 28,
      },
      postMatchEnd: {
        hours: 22,
        minutes: 45,
      },
      digitalHightlightClips: {
        hours: 23,
        minutes: 30,
      },
      slowMotionPlayOuts: {
        hours: 0,
        minutes: 30,
      },
      postMatchInterviews: {
        hours: 1,
        minutes: 30,
      },
    },
  ],
  [
    RundownSelectMenuValues.saturdayAfternoon,
    {
      preMatchStart: {
        hours: 14,
        minutes: 30,
      },
      preMatchEnd: {
        hours: 15,
        minutes: 20,
      },
      liveMatchFirstHalfStart: {
        hours: 15,
        minutes: 30,
      },
      liveMatchFirstHalfEnd: {
        hours: 16,
        minutes: 16,
      },
      liveMatchSecondHalfStart: {
        hours: 16,
        minutes: 33,
      },
      liveMatchSecondHalfEnd: {
        hours: 17,
        minutes: 18,
      },
      postMatchStart: {
        hours: 17,
        minutes: 28,
      },
      postMatchEnd: {
        hours: 17,
        minutes: 45,
      },
      digitalHightlightClips: {
        hours: 18,
        minutes: 30,
      },
      slowMotionPlayOuts: {
        hours: 19,
        minutes: 30,
      },
      postMatchInterviews: {
        hours: 20,
        minutes: 30,
      },
    },
  ],
  [
    RundownSelectMenuValues.saturdayNight,
    {
      preMatchStart: {
        hours: 17,
        minutes: 30,
      },
      preMatchEnd: {
        hours: 18,
        minutes: 20,
      },
      liveMatchFirstHalfStart: {
        hours: 18,
        minutes: 30,
      },
      liveMatchFirstHalfEnd: {
        hours: 19,
        minutes: 16,
      },
      liveMatchSecondHalfStart: {
        hours: 19,
        minutes: 33,
      },
      liveMatchSecondHalfEnd: {
        hours: 20,
        minutes: 18,
      },
      postMatchStart: {
        hours: 20,
        minutes: 28,
      },
      postMatchEnd: {
        hours: 20,
        minutes: 45,
      },
      digitalHightlightClips: {
        hours: 21,
        minutes: 30,
      },
      slowMotionPlayOuts: {
        hours: 22,
        minutes: 30,
      },
      postMatchInterviews: {
        hours: 23,
        minutes: 30,
      },
    },
  ],
  [
    RundownSelectMenuValues.sundayLunch,
    {
      preMatchStart: {
        hours: 14,
        minutes: 30,
      },
      preMatchEnd: {
        hours: 15,
        minutes: 20,
      },
      liveMatchFirstHalfStart: {
        hours: 15,
        minutes: 30,
      },
      liveMatchFirstHalfEnd: {
        hours: 16,
        minutes: 16,
      },
      liveMatchSecondHalfStart: {
        hours: 16,
        minutes: 33,
      },
      liveMatchSecondHalfEnd: {
        hours: 17,
        minutes: 18,
      },
      postMatchStart: {
        hours: 17,
        minutes: 28,
      },
      postMatchEnd: {
        hours: 17,
        minutes: 45,
      },
      digitalHightlightClips: {
        hours: 18,
        minutes: 30,
      },
      slowMotionPlayOuts: {
        hours: 19,
        minutes: 30,
      },
      postMatchInterviews: {
        hours: 20,
        minutes: 30,
      },
    },
  ],
  [
    RundownSelectMenuValues.sundayAfternoon,
    {
      preMatchStart: {
        hours: 16,
        minutes: 30,
      },
      preMatchEnd: {
        hours: 17,
        minutes: 20,
      },
      liveMatchFirstHalfStart: {
        hours: 17,
        minutes: 30,
      },
      liveMatchFirstHalfEnd: {
        hours: 18,
        minutes: 16,
      },
      liveMatchSecondHalfStart: {
        hours: 18,
        minutes: 33,
      },
      liveMatchSecondHalfEnd: {
        hours: 19,
        minutes: 18,
      },
      postMatchStart: {
        hours: 19,
        minutes: 28,
      },
      postMatchEnd: {
        hours: 19,
        minutes: 45,
      },
      digitalHightlightClips: {
        hours: 20,
        minutes: 30,
      },
      slowMotionPlayOuts: {
        hours: 21,
        minutes: 30,
      },
      postMatchInterviews: {
        hours: 22,
        minutes: 30,
      },
    },
  ],
  [
    RundownSelectMenuValues.sundayNight,
    {
      preMatchStart: {
        hours: 18,
        minutes: 30,
      },
      preMatchEnd: {
        hours: 19,
        minutes: 20,
      },
      liveMatchFirstHalfStart: {
        hours: 19,
        minutes: 30,
      },
      liveMatchFirstHalfEnd: {
        hours: 20,
        minutes: 16,
      },
      liveMatchSecondHalfStart: {
        hours: 20,
        minutes: 33,
      },
      liveMatchSecondHalfEnd: {
        hours: 21,
        minutes: 18,
      },
      postMatchStart: {
        hours: 21,
        minutes: 28,
      },
      postMatchEnd: {
        hours: 21,
        minutes: 43,
      },
      digitalHightlightClips: {
        hours: 22,
        minutes: 30,
      },
      slowMotionPlayOuts: {
        hours: 23,
        minutes: 30,
      },
      postMatchInterviews: {
        hours: 0,
        minutes: 30,
      },
    },
  ],
  /*[
    RundownSelectMenuValues.mondayNight,
    {
      preMatchStart: {
        hours: 19,
        minutes: 30,
      },
      preMatchEnd: {
        hours: 20,
        minutes: 20,
      },
      liveMatchFirstHalfStart: {
        hours: 20,
        minutes: 30,
      },
      liveMatchFirstHalfEnd: {
        hours: 21,
        minutes: 16,
      },
      liveMatchSecondHalfStart: {
        hours: 21,
        minutes: 33,
      },
      liveMatchSecondHalfEnd: {
        hours: 22,
        minutes: 18,
      },
      postMatchStart: {
        hours: 22,
        minutes: 23,
      },
      postMatchEnd: {
        hours: 22,
        minutes: 45,
      },
      digitalHightlightClips: {
        hours: 23,
        minutes: 20,
      },
      slowMotionPlayOuts: {
        hours: 0,
        minutes: 30,
      },
      postMatchInterviews: {
        hours: 1,
        minutes: 30,
      },
    },
  ],*/
  [
    RundownSelectMenuValues.tuesdayNight,
    {
      preMatchStart: {
        hours: 17,
        minutes: 30,
      },
      preMatchEnd: {
        hours: 18,
        minutes: 20,
      },
      liveMatchFirstHalfStart: {
        hours: 18,
        minutes: 30,
      },
      liveMatchFirstHalfEnd: {
        hours: 19,
        minutes: 16,
      },
      liveMatchSecondHalfStart: {
        hours: 19,
        minutes: 33,
      },
      liveMatchSecondHalfEnd: {
        hours: 20,
        minutes: 18,
      },
      postMatchStart: {
        hours: 20,
        minutes: 28,
      },
      postMatchEnd: {
        hours: 20,
        minutes: 45,
      },
      digitalHightlightClips: {
        hours: 21,
        minutes: 30,
      },
      slowMotionPlayOuts: {
        hours: 22,
        minutes: 30,
      },
      postMatchInterviews: {
        hours: 23,
        minutes: 30,
      },
    },
  ],
  [
    RundownSelectMenuValues.tuesdayLateNight,
    {
      preMatchStart: {
        hours: 19,
        minutes: 30,
      },
      preMatchEnd: {
        hours: 20,
        minutes: 20,
      },
      liveMatchFirstHalfStart: {
        hours: 20,
        minutes: 30,
      },
      liveMatchFirstHalfEnd: {
        hours: 21,
        minutes: 16,
      },
      liveMatchSecondHalfStart: {
        hours: 21,
        minutes: 33,
      },
      liveMatchSecondHalfEnd: {
        hours: 22,
        minutes: 18,
      },
      postMatchStart: {
        hours: 22,
        minutes: 28,
      },
      postMatchEnd: {
        hours: 22,
        minutes: 45,
      },
      digitalHightlightClips: {
        hours: 23,
        minutes: 30,
      },
      slowMotionPlayOuts: {
        hours: 0,
        minutes: 30,
      },
      postMatchInterviews: {
        hours: 1,
        minutes: 30,
      },
    },
  ],
  [
    RundownSelectMenuValues.wednesdayNight,
    {
      preMatchStart: {
        hours: 17,
        minutes: 30,
      },
      preMatchEnd: {
        hours: 18,
        minutes: 20,
      },
      liveMatchFirstHalfStart: {
        hours: 18,
        minutes: 30,
      },
      liveMatchFirstHalfEnd: {
        hours: 19,
        minutes: 16,
      },
      liveMatchSecondHalfStart: {
        hours: 19,
        minutes: 33,
      },
      liveMatchSecondHalfEnd: {
        hours: 20,
        minutes: 18,
      },
      postMatchStart: {
        hours: 20,
        minutes: 28,
      },
      postMatchEnd: {
        hours: 20,
        minutes: 45,
      },
      digitalHightlightClips: {
        hours: 21,
        minutes: 30,
      },
      slowMotionPlayOuts: {
        hours: 22,
        minutes: 30,
      },
      postMatchInterviews: {
        hours: 23,
        minutes: 30,
      },
    },
  ],
  [
    RundownSelectMenuValues.wednesdayLateNight,
    {
      preMatchStart: {
        hours: 19,
        minutes: 30,
      },
      preMatchEnd: {
        hours: 20,
        minutes: 20,
      },
      liveMatchFirstHalfStart: {
        hours: 20,
        minutes: 30,
      },
      liveMatchFirstHalfEnd: {
        hours: 21,
        minutes: 16,
      },
      liveMatchSecondHalfStart: {
        hours: 21,
        minutes: 33,
      },
      liveMatchSecondHalfEnd: {
        hours: 22,
        minutes: 18,
      },
      postMatchStart: {
        hours: 22,
        minutes: 28,
      },
      postMatchEnd: {
        hours: 22,
        minutes: 45,
      },
      digitalHightlightClips: {
        hours: 23,
        minutes: 30,
      },
      slowMotionPlayOuts: {
        hours: 0,
        minutes: 30,
      },
      postMatchInterviews: {
        hours: 1,
        minutes: 30,
      },
    },
  ],
]);
