import styled from "styled-components";
import {stage, fonts, fontSizesPx, colors, breakpoint, breakpoints, mobilePaddings} from "../models";
import {columnSpan} from "../util/util";

export const px = (value: number): string => `${value}px`;

export const baseFontProps = {
  "font-family": fonts.headerFont,
  "font-size": fontSizesPx.base,
  "font-weight": `${fonts.weightRegular}`,
  "text-align": "left",
  color: colors.black,
};

export const condensedFontProps = {
  "font-family": fonts.condensedFont,
  "font-size": fontSizesPx.base,
  "font-weight": `${fonts.weightRegular}`,
  "text-align": "left",
  color: colors.black,
};

// -03 macro
export const macroRegular = {
  ...baseFontProps,
  "font-size": fontSizesPx.macro,
};
export const macroBold = {
  ...macroRegular,
  "font-weight": fonts.weightBold,
};

// -02 micro
export const microRegular = {
  ...baseFontProps,
  "font-size": fontSizesPx.micro,
};
export const microBold = {
  ...microRegular,
  "font-weight": fonts.weightBold,
};

// -01 small
export const smallRegular = {
  ...baseFontProps,
  "font-size": fontSizesPx.small,
};
export const smallBold = {
  ...smallRegular,
  "font-weight": fonts.weightBold,
};

// 00 base
export const baseRegular = {
  ...baseFontProps,
};
export const baseBold = {
  ...baseFontProps,
  "font-weight": fonts.weightBold,
};
export const baseSemiBold = {
  ...baseFontProps,
  "font-weight": fonts.weightSemiBold,
};
export const baseItalic = {
  ...baseFontProps,
  "font-style": fonts.styleItalic,
};

// +01 medium
export const mediumRegular = {
  ...baseRegular,
  "font-size": fontSizesPx.medium,
};
export const mediumBold = {
  ...mediumRegular,
  "font-weight": fonts.weightBold,
};
export const mediumLight = {
  ...mediumRegular,
  "font-weight": fonts.weightLight,
};

// +02 big
export const bigRegular = {
  ...baseRegular,
  "font-size": fontSizesPx.big,
};
export const bigBold = {
  ...bigRegular,
  "font-weight": fonts.weightBold,
};

// +03 large
export const largeRegular = {
  ...baseRegular,
  "font-size": fontSizesPx.large,
};
export const largeBold = {
  ...largeRegular,
  "font-weight": fonts.weightBold,
};

// +04 huge
export const hugeRegular = {
  ...baseRegular,
  "font-size": fontSizesPx.huge,
};
export const hugeBold = {
  ...hugeRegular,
  "font-weight": fonts.weightBold,
};

// +05 gigantic
export const giganticBold = {
  ...baseBold,
  "font-size": fontSizesPx.gigantic,
};

export const FullpageHeadline = styled.h1`
  ${mediumBold};
  font-size: 20px;
  letter-spacing: 0.24em;
  line-height: 1.3em;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  padding: 0 ${px(mobilePaddings.mobile)};
  margin: 0 0 23px 0;
  box-sizing: border-box;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    font-size: 25px;
    padding: 0 ${px(mobilePaddings.tablet)};
    margin: 0 auto 25px;
  }

  /* Desktop HD */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    width: ${px(columnSpan(8, stage.columnWidth, stage.columnGap))};
  }
`;

export const FullpageSubheadline = styled.p`
  ${baseRegular};
  font-size: 15px;
  line-height: 1.5em;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0 ${px(mobilePaddings.mobile)};
  box-sizing: border-box;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    padding: 0 ${px(mobilePaddings.tablet)};
    font-size: 20px;
  }

  /* Desktop HD */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    width: ${px(columnSpan(8, stage.columnWidth, stage.columnGap))};
    padding: 0;
  }
`;

// CONDENSED
// -03 macro
export const condensedMacroRegular = {
  ...condensedFontProps,
  "font-size": fontSizesPx.macro,
};
export const condensedMacroBold = {
  ...condensedMacroRegular,
  "font-weight": fonts.weightBold,
};

// -02 micro
export const condensedMicroRegular = {
  ...condensedFontProps,
  "font-size": fontSizesPx.micro,
};
export const condensedMicroBold = {
  ...condensedMicroRegular,
  "font-weight": fonts.weightBold,
};

// -01 small
export const condensedSmallRegular = {
  ...condensedFontProps,
  "font-size": fontSizesPx.small,
};
export const condensedSmallBold = {
  ...condensedSmallRegular,
  "font-weight": fonts.weightBold,
};

// 00 base
export const condensedBaseRegular = {
  ...condensedFontProps,
};
export const condensedBaseBold = {
  ...condensedFontProps,
  "font-weight": fonts.weightBold,
};

// +01 medium
export const condensedMediumRegular = {
  ...condensedBaseRegular,
  "font-size": fontSizesPx.medium,
};
export const condensedMediumBold = {
  ...condensedMediumRegular,
  "font-weight": fonts.weightBold,
};

// +02 big
export const condensedBigRegular = {
  ...condensedBaseRegular,
  "font-size": fontSizesPx.big,
};
export const condensedBigBold = {
  ...condensedBigRegular,
  "font-weight": fonts.weightBold,
};

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CenteredDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const GridColumnDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
`;

interface MobileVisualWrapperProps {
  visualWidth: number;
}
export const MobileVisualWrapper = styled.div`
  width: 100%;
  height: ${(props: MobileVisualWrapperProps) => px((props.visualWidth / 16) * 9)};
  overflow: hidden;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    width: ${px(stage.innerWidthTablet)};
    height: ${px((stage.innerWidthTablet / 16) * 9)};
    margin: 0 auto;
    overflow: hidden;
  }
`;

export const FullWidthImage = styled.img`
  width: 100%;
`;

// INTRO Desktop
export const DesktopIntro = styled.div`
  display: none;

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    display: block;
  }
`;

// INTRO Mobile
export const MobileIntro = styled.div`
  display: block;
  padding-top: 70px;

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    display: none;
  }
`;

export const ElementWrapper = styled.div<{bgColor?: string; hasPadding?: boolean}>`
  margin-bottom: 50px;
  padding: ${p => (p.hasPadding ? "55px 30px" : "0")};
  background-color: ${p => (p.bgColor ? p.bgColor : colors.white)};

  ${breakpoint.s.up} {
    padding: ${p => (p.hasPadding ? "100px 30px" : "0")};
  };

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    margin-bottom: 100px;
  }

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    margin-bottom: 120px;
  }
`;

export const MobileCaption = styled.div`
  ${smallBold};
  color: ${colors.redDFL};
  margin: 36px 0 20px;
  padding: 0 22px;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    width: ${px(stage.innerWidthTablet)};
    margin: 36px auto 20px;
    font-size: 20px;
  }
`;

export const MobileTitle = styled.div`
  ${bigBold};
  font-size: 35px;
  line-height: 1.3em;
  margin-bottom: 48px;
  padding: 0 22px;
  text-transform: uppercase;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    width: ${px(stage.outerWidthTablet)};
    margin: 0 auto 48px;
    font-size: 60px;
  }
`;

export const MobileIntroText = styled.div`
  ${mediumLight};
  font-size: 20px;
  line-height: 1.6em;
  padding: 0 22px;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    width: ${px(stage.innerWidthTablet)};
    margin: 0 auto 48px;
  }
`;

/* NOTE: To prevent overflow-x and backgound from
  scrolling up when the Products Overlay is visible
  in iOS. The drawback to this approach is the iOS
  address bar will not minimize. */
export const MainContentWrapper = styled.div`
  @media (max-width: ${px(breakpoints.desktopHd)}) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
`;

export const FooterWrapper = styled.div`
  margin-top: 96px;
`;
