import {WeekDayType, SelectOptionProps} from "../../models";

export interface RundownState {
  selectedDateOption: any;
  selectedWeekDay: WeekDayType;
  selectedPreMatchStartAndEndTime: [number, number, number, number];
  selectedLiveMatchFirstHalfStartAndEndTime: [number, number, number, number];
  selectedLiveMatchSecondHalfStartAndEndTime: [number, number, number, number];
  selectedPostMatchStartAndEndTime: [number, number, number, number];
  selectedDigitalHighlightsClipsTime: [number, number];
  selectedSlowMotionPlayOutsTime: [number, number];
  selectedPostMatchInterviewsTime: [number, number];
}

export const UPDATE_SELECTED_DATE_OPTION = "UPDATE_SELECTED_DATE_OPTION";
interface UpdateSelectedDateOption {
  type: typeof UPDATE_SELECTED_DATE_OPTION;
  payload: SelectOptionProps[];
}

export const UPDATE_SELECTED_WEEK_DAY = "UPDATE_SELECTED_WEEK_DAY";
interface UpdateSelectedWeekday {
  type: typeof UPDATE_SELECTED_WEEK_DAY;
  payload: WeekDayType;
}

export const UPDATE_SELECTED_PREMATCH_START_AND_END_TIME = "UPDATE_SELECTED_PREMATCH_START_AND_END_TIME";
interface UpdateSelectedPreMatchStartAndEndTime {
  type: typeof UPDATE_SELECTED_PREMATCH_START_AND_END_TIME;
  payload: [number, number, number, number];
}

export const UPDATE_SELECTED_LIVEMATCH_FIRST_HALF_START_AND_END_TIME =
  "UPDATE_SELECTED_LIVEMATCH_FIRST_HALF_START_AND_END_TIME";
interface UpdateSelectedLiveMatchFirstHalfStartAndEndTime {
  type: typeof UPDATE_SELECTED_LIVEMATCH_FIRST_HALF_START_AND_END_TIME;
  payload: [number, number, number, number];
}

export const UPDATE_SELECTED_LIVEMATCH_SECOND_HALF_START_AND_END_TIME =
  "UPDATE_SELECTED_LIVEMATCH_SECOND_HALF_START_AND_END_TIME";

interface UpdateSelectedLiveMatchSecondHalfStartAndEndTime {
  type: typeof UPDATE_SELECTED_LIVEMATCH_SECOND_HALF_START_AND_END_TIME;
  payload: [number, number, number, number];
}

export const UPDATE_SELECTED_POST_MATCH_START_AND_END_TIME = "UPDATE_SELECTED_POST_MATCH_START_AND_END_TIME";

interface UpdateSelectedPostMatchStartAndEndTime {
  type: typeof UPDATE_SELECTED_POST_MATCH_START_AND_END_TIME;
  payload: [number, number, number, number];
}

export const UPDATE_SELECTED_DIGITAL_HIGHLIGHTS_CLIPS_TIME = "UPDATE_SELECTED_DIGITAL_HIGHTLIGHTS_CLIPS_TIME";

interface UpdateSelectedDigitalHighlightsClipsTime {
  type: typeof UPDATE_SELECTED_DIGITAL_HIGHLIGHTS_CLIPS_TIME;
  payload: [number, number];
}

export const UPDATE_SELECTED_SLOW_MOTION_PLAY_OUTS_TIME = "UPDATE_SELECTED_SLOW_MOTION_PLAY_OUTS_TIME";

interface UpdateSelectedSlowMotionPlayOutsTime {
  type: typeof UPDATE_SELECTED_SLOW_MOTION_PLAY_OUTS_TIME;
  payload: [number, number];
}

export const UPDATE_SELECTED_POST_MATCH_INTERVIEWS_TIME = "UPDATE_SELECTED_POST_MATCH_INTERVIEWS_TIME";

interface UpdateSelectedPostMatchInterviewsTime {
  type: typeof UPDATE_SELECTED_POST_MATCH_INTERVIEWS_TIME;
  payload: [number, number];
}

export type RundownActionTypes =
  | UpdateSelectedDateOption
  | UpdateSelectedWeekday
  | UpdateSelectedPreMatchStartAndEndTime
  | UpdateSelectedLiveMatchFirstHalfStartAndEndTime
  | UpdateSelectedLiveMatchSecondHalfStartAndEndTime
  | UpdateSelectedPostMatchStartAndEndTime
  | UpdateSelectedDigitalHighlightsClipsTime
  | UpdateSelectedSlowMotionPlayOutsTime
  | UpdateSelectedPostMatchInterviewsTime;
