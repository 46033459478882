//import styledBreakpoint from "@humblebee/styled-components-breakpoint";

export enum dflRegions {
  emea = "EMEA",
  americas = "Americas",
  asia = "Asia",
}
export type DflRegionType = dflRegions;

export enum tzDatabaseRegions {
  africa = "Africa",
  america = "America",
  antarctica = "Antarctica",
  arctic = "Arctic",
  asia = "Asia",
  atlantic = "Atlantic",
  australia = "Australia",
  europe = "Europe",
  indian = "Indian",
  pacific = "Pacific",
}
export type TzDatabaseRegionType = tzDatabaseRegions;

export enum wordpressPageIds {
  homepage = 41,
  digitalContent = 21,
  promoTrailerContent = 19,
  seasonalContent = 17,
  monthlyContent = 15,
  weeklyContent = 13,
  matchInterviews = 11,
  matchHighlights = 9,
  bundesligaMatches = 6,
  privacyPolicy = 940,
  legalNotice = 933,
  imprint = 930,
  whatsNew = 1272,
  contacts = 1,
}

export enum stage {
  outerWidth = 1200,
  innerWidth = 996,
  maxWidth = 1800,
  columnWidth = 69,
  columns = 12,
  columnGap = (outerWidth - columnWidth * columns) / (columns - 1),

  outerWidthTablet = 728,
  innerWidthTablet = 604,
  columnWidthTablet = 41,
}

export enum timings {
  instant = 50,
  hurried = 125,
  quick = 250,
  moderate = 350,
  easygoing = 500,
  slow = 750,
  sluggish = 100,
}

export enum colors {
  transparent = "rgba(0, 0, 0, 0)",
  white = "#FFF",
  whiteAlpha60 = "rgba(255, 255, 255, 0.60)",
  black = "#00141E",
  blackAlpha10 = "rgba(0, 20, 30, 0.10)",
  blackAlpha12 = "rgba(0, 20, 30, 0.12)",
  blackAlpha14 = "rgba(0, 20, 30, 0.14)",
  blackAlpha20 = "rgba(0, 20, 30, 0.2)",
  blackAlpha90 = "rgba(0, 20, 30, 0.9)",
  grayDark2 = "#979797",
  grayDark = "#334B55",
  grayDarkLight = "#DEDEDE",
  grayDarkLight2 = "#B9B9B9",
  grayMediumDark = "#697D87",
  grayMedium = "#96AAB4",
  grayLight = "#B4BEC8",
  grayUltraLight = "#EFEFEF",

  redDFL = "#D20515",
  redDFL90 = "#E9391A",
  redDFL70 = "#EE654D",
  redDFL30 = "#F2917F",
}
export type ColorType = colors;

export enum fonts {
  headerFont = "'DFL - webfonts', 'Open Sans', Helvetica, Arial,serif",
  condensedFont = "'DFL Condensed - webfonts', 'Open Sans', Helvetica, Arial,serif",
  weightLight = "300",
  weightRegular = "400",
  weightSemiBold = "600",
  weightBold = "700",
  styleItalic = "italic",
}

export enum fontSizesPx {
  macro = "11px",
  micro = "13px",
  xsSmall = "14px",
  small = "15px",
  base = "16px",
  average = "20px",
  medium = "25px",
  mediumBig = "30px",
  big = "35px",
  large = "60px",
  huge = "100px",
  gigantic = "140px",
}

export enum zIndices {
  topMost = 1000,
  zNavigation = topMost - 1,
  zNavigationElements = zNavigation - 1,
  zNavigationOverlay = zNavigationElements - 1,

  zConsentBanner = zNavigation + 1,

  zDropdown = zNavigationOverlay - 1,

  zTileOverlay = topMost - 100,
  zTileText = zTileOverlay + 1,
}

export enum productContainerTypes {
  threeColumn = "WordPressAcf_threecolumn",
  productWithImage = "WordPressAcf_productwithimage",
  productWithVideo = "WordPressAcf_productwithvideo",
}

export enum whatsNewContainerTypes {
  contentWithImage = "ContentWithImage",
  contentWithVideo = "ContentWithVideo",
}

export type ProductContainerType = productContainerTypes;
export type WhatsNewContainerType = whatsNewContainerTypes;

export enum elementSizes {
  navigationHeaderHeight = 80,
  productPageMarginTop = 100,
  dropdownHeight = 22,
  navigationHeaderDropdownMenuXPadding = 16,
}

export enum scheduleSizes {
  buttonSize = 48,
  navigationRow = 30,
  dayLabelRow = 25,
  buttonColumn = 75,
  categoryAggregationLabelColumn = 44,
  categoryLabelColumn = 60,
  categoryLabelPadding = 6,
  collapsedColumnWidth = 40,
}

// product categories
export enum productCategories {
  bundesligaMatches,
  matchHighlights,
  matchInterviews,
  digitalContent,
  weeklyContent,
  monthlyContent,
  seasonalContent,
  promoTrailerContent,
}

export type ProductCategoryType = productCategories;

export enum productCategorySlugs {
  bundesligaMatches = "bundesliga-matches",
  matchHighlights = "match-highlights",
  matchInterviews = "match-interviews",
  digitalContent = "digital-content",
  weeklyContent = "weekly-content",
  monthlyContent = "monthly-content",
  seasonalContent = "seasonal-content",
  promoTrailerContent = "promo-trailer-content",
}
export type ProductCategorySlugType = productCategorySlugs;

export enum productCategoryTitles {
  bundesligaMatches = "Bundesliga Live Matches",
  matchHighlights = "Match Highlights",
  matchInterviews = "Match Interviews",
  digitalContent = "Digital Content",
  weeklyContent = "Weekly Content",
  monthlyContent = "Monthly Content",
  seasonalContent = "Seasonal Content",
  promoTrailerContent = "Promo & Trailer Content",
}
export type ProductCategoryTitleType = productCategoryTitles;

// the keys of the category select in Wordpress
export enum productCategoryWordpressKeys {
  bundesligaMatches = "bundesligaMatches",
  matchHighlights = "matchHighlights",
  matchInterviews = "matchInterviews",
  digitalContent = "digitalContent",
  weeklyContent = "weeklyContent",
  monthlyContent = "monthlyContent",
  seasonalContent = "seasonalContent",
  promoTrailerContent = "promoTrailerContent",
}
export type ProductCategoryWordpressKeyType = productCategoryWordpressKeys;

export enum staticPages {
  contact,
  whatsNew,
  imprint,
  legalNotice,
  privacyPolicy,
}
export type StaticPageType = staticPages;

export enum fullPageSections {
  mainStage = "mainStage",
  categories = "categories",
  uspMessage = "uspMessage",
  rundown = "rundown",
  schedule = "schedule",
  infographics = "infographics",
  contact = "contact",
}

export enum navigationOverlayStates {
  default,
  productsActive,
  mobileActive,
}
export type NavigationOverlayStateType = navigationOverlayStates;

export enum categoryClasses {
  matchday = "Matchday",
  promotion = "Promotion",
  storytelling = "Storytelling",
}
export type CategoryClassType = categoryClasses;

export enum productWithDecorations {
  none = "none",
  new = "new",
  enhancement = "enhancement",
}
export type ProductWithDecorationType = productWithDecorations;

export enum productWithImageSizes {
  small = "small",
  medium = "medium",
  large = "large",
}
export type ProductWithImageSizeType = productWithImageSizes;

export enum productWithVideoSizes {
  medium = "medium",
  large = "large",
}
export type ProductWithVideoSizeType = productWithVideoSizes;

export enum layouts {
  left = "left",
  right = "right",
}
export type LayoutType = layouts;

export enum weekDays {
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
}
export type WeekDayType = weekDays;

export enum scheduleViews {
  aroundMatchdays,
  aroundSeason,
}
export type ScheduleViewType = scheduleViews;

export interface ScheduleCategoryContentProps {
  days: ScheduleCellContentProps[];
  backgroundColor: ColorType;
  labelColor: ColorType;
  textColor: ColorType;
  categoryName: string;
}

export const scheduleWeek = [
  weekDays.wednesday,
  weekDays.thursday,
  weekDays.friday,
  weekDays.saturday,
  weekDays.sunday,
  weekDays.monday,
  weekDays.tuesday,
];

/**
 * SMP = Slow Motion Playout
 * DHC = Digital Highlights Clips
 * PreMI = Pre-Match Interview
 * PoMI = Post-Match Interview
 * SFI = Super-Flash Interview
 */
export enum broadcastProducts {
  LIVE_FR_1830,
  LIVE_SA_1300,
  LIVE_SA_2030,
  LIVE_SO_1330,
  LIVE_FR_2030,
  LIVE_FR_1945_IF,
  LIVE_SA_1530_IF,
  LIVE_SA_1530_GoAr,
  LIVE_SA_1830_IF,
  LIVE_FR_2030_SMP,
  LIVE_FR_2030_DHC,
  LIVE_FR_2030_NLC,
  LIVE_FR_2030_SCG,
  LIVE_FR_2030_PreMI,
  LIVE_FR_2030_SFI,
  LIVE_FR_2030_PoMI,
  LIVE_FR_1830_SMMF,
  LIVE_SA_1330_SMMF,
  LIVE_SA_1630_SMMF,
  LIVE_SA_1530,
  LIVE_SA_1530_IHF,
  LIVE_SA_1530_SMP,
  LIVE_SA_1530_DHC,
  LIVE_SA_1530_PreMI,
  LIVE_SA_1530_SFI,
  LIVE_SA_1530_PoMI,
  LIVE_SA_1830,
  LIVE_SA_1830_SMP,
  LIVE_SA_1830_DHC,
  LIVE_SA_1830_PreMI,
  LIVE_SA_1830_SFI,
  LIVE_SA_1830_PoMI,
  LIVE_SU_1530,
  LIVE_SU_1530_SMP,
  LIVE_SU_1530_DHC,
  LIVE_SU_1530_PreMI,
  LIVE_SU_1530_SFI,
  LIVE_SU_1530_PoMI,
  LIVE_SU_1800,
  LIVE_SU_1930,
  LIVE_SU_1800_SMP,
  LIVE_SU_1800_DHC,
  LIVE_SU_1800_PreMI,
  LIVE_SU_1800_SFI,
  LIVE_SU_1800_PoMI,
  LIVE_SU_1530_IF,
  LIVE_SU_1730_IF,
  LIVE_SU_1930_IF,
  LIVE_SU_1330_SMMF,
  LIVE_SU_1600_SMMF,
  LIVE_MO_1000_CC,
  HIGHLIGHTS_I,
  HIGHLIGHTS_II,
  ROUNDUP_CLIPS_I,
  ROUNDUP_CLIPS_II,
  MATCHDAY_CLIPS,
  LOCALIZED_CLIPS,
  DAILY_NEWS_MON,
  DAILY_NEWS_TUE,
  DAILY_NEWS_WED,
  DAILY_NEWS_THU,
  DAILY_NEWS_FRI,
  MIDWEEK_INVU_MON,
  MIDWEEK_INVU_TUE,
  MIDWEEK_INVU_WED,
  MIDWEEK_INVU_THU,
  MIDWEEK_INVU_FRI,
  TOPMATCH_PROMO_FRI,
  TOPMATCH_PROMO_SAT,
  TOPMATCH_PROMO_SUN,
  TOPMATCH_PROMO_THU,
  BULI_WEEKLY,
  CONF_FEED_THU,
  CONF_FEED_FRI,
  BEST_OF_MONTH,
  INVU_OF_MONTH,
  BULI_REPORT,
  BULI_SERIES,
  MONTH_SPECIALS,
  BULI_CLUBS,
  SEASON_SPECIALS,
  BULI_ANALYSIS,
  MEDIA_KIT,
  MEDIA_DAYS,
  SUMMER_SPECIAL,
  SEASAON_TICKET_PRE,
  SEASAON_TICKET_MID,
  SEASAON_TICKET_POST,
  FAIMTB_TRAILER,
  SEASAON_TRAILER,
  PROMO_TRAILER,
  SOCMED_CONTENT_SERVICE_MON,
  SOCMED_CONTENT_SERVICE_TUE,
  SOCMED_CONTENT_SERVICE_WED,
  SOCMED_CONTENT_SERVICE_THU,
  WEEKLY_NEWSLETTER,
  SUPERCUP_MATCH,
  RELEGATION_PLAYOFF_MATCH,
  UHD_MATCH,
  BULI_ENGAGEMENT_WINTER,
  BULI_ENGAGEMENT_SUMMER,
  SEASON_TRAILER,
  BULI_PLATFORM_SYNERGY,
  BULI_WEEKLY_LOCALISED,
  SEASON_CUSTOMISED_CLIPS_MID,
  SEASON_BEST_OF_MONTH_MID,
  SEASON_BEST_OF_MONTH_LOCALISED_MID,
  SEASON_TICKET_LOCALISED_PRE,
  SEASON_TICKET_LOCALISED_MID,
  SEASON_TICKET_LOCALISED_POST,
  CUSTOMISED_CLIPS_FR,
  CUSTOMISED_CLIPS_SA,
  CUSTOMISED_CLIPS_SO,
  TECH_SERVICES,
}
export type BroadcastProductType = broadcastProducts;

export const broadcastSynergyGroups: BroadcastProductType[][] = [
  // friday synergies
  [
    broadcastProducts.LIVE_FR_2030,
    broadcastProducts.LIVE_FR_2030_SMP,
    broadcastProducts.LIVE_FR_2030_DHC,
    broadcastProducts.LIVE_FR_2030_PreMI,
    broadcastProducts.LIVE_FR_2030_SFI,
    broadcastProducts.LIVE_FR_2030_PoMI,
  ],

  // saturday synergies 1
  [
    broadcastProducts.LIVE_SA_1530,
    broadcastProducts.LIVE_SA_1530_SMP,
    broadcastProducts.LIVE_SA_1530_DHC,
    broadcastProducts.LIVE_SA_1530_IHF,
    broadcastProducts.LIVE_SA_1530_PreMI,
    broadcastProducts.LIVE_SA_1530_SFI,
    broadcastProducts.LIVE_SA_1530_PoMI,
  ],
  // saturday synergies 2
  [
    broadcastProducts.LIVE_SA_1830,
    broadcastProducts.LIVE_SA_1830_SMP,
    broadcastProducts.LIVE_SA_1830_DHC,
    broadcastProducts.LIVE_SA_1830_PreMI,
    broadcastProducts.LIVE_SA_1830_SFI,
    broadcastProducts.LIVE_SA_1830_PoMI,
  ],

  // sunday synergies 1
  [
    broadcastProducts.LIVE_SU_1530,
    broadcastProducts.LIVE_SU_1530_SMP,
    broadcastProducts.LIVE_SU_1530_DHC,
    broadcastProducts.LIVE_SU_1530_PreMI,
    broadcastProducts.LIVE_SU_1530_SFI,
    broadcastProducts.LIVE_SU_1530_PoMI,
  ],

  // saturday synergies 2
  [
    broadcastProducts.LIVE_SU_1800,
    broadcastProducts.LIVE_SU_1800_SMP,
    broadcastProducts.LIVE_SU_1800_DHC,
    broadcastProducts.LIVE_SU_1800_PreMI,
    broadcastProducts.LIVE_SU_1800_SFI,
    broadcastProducts.LIVE_SU_1800_PoMI,
  ],
];

export interface BroadcastTimeProps {
  anyTime: boolean;
  day: WeekDayType;
  hours: number;
  minutes: number;
}

export interface BroadcastProductDescriptionProps {
  id: BroadcastProductType;
  time?: BroadcastTimeProps;
}
export interface BroadcastGroupDescriptionProps {
  label: string;
  subLabel?: string;
  productLink: string;
  broadcasts: BroadcastProductDescriptionProps[];
}
interface ScheduleCellContentProps {
  weekDay: WeekDayType;
  broadcastGroups: BroadcastGroupDescriptionProps[];
}
export interface ScheduleCategoryContentProps {
  days: ScheduleCellContentProps[];
  backgroundColor: ColorType;
  textColor: ColorType;
  categoryName: string;
}

export enum seasons {
  preSeason,
  season,
  postSeason,
}
export type SeasonType = seasons;
export interface SeasonalProductProps {
  label: string;
  description: string;
  productLink: string;
  enhancement?: {
    color: ColorType;
    label: string;
  };
}
interface ScheduleAroundSeasonCellContentProps {
  season: SeasonType;
  seasonalProducts: SeasonalProductProps[];
}
export interface ScheduleAroundSeasonContentProps {
  seasons: ScheduleAroundSeasonCellContentProps[];
  backgroundColor: ColorType;
  labelColor: ColorType;
  textColor: ColorType;
  categoryName: string;
}
export interface ScheduleContentProps {
  aroundMatchdaysCategories: ScheduleCategoryContentProps[];
  aroundSeasonCategories: ScheduleAroundSeasonContentProps[];
}

// TIMEZONE OFFSET
export const DFL_UTC_OFFSET = 1;

export enum utcTimeStates {
  localTime,
  germanTime,
}
export type UTCTimeStateType = utcTimeStates;

// Text Filter
export interface SearchableProductProps {
  name: string;
  subline: string;
  link: string;
  type: "product" | "category";
}
export const suggestionSublineMaxLength = 42;

// Rundown
export enum rundownBlockSizes {
  s,
  m,
  l,
}
export type RundownBlockSizeType = rundownBlockSizes;

export enum rundownElementTypes {
  block,
  space,
}
export type RundownElementType = rundownElementTypes;

export interface RundownBlockProps {
  size: RundownBlockSizeType;
  type: rundownElementTypes.block;
  color: ColorType;
  label?: string;
  subLabel?: string;
  description?: string;
  link?: {
    url: string;
    label: string;
  };
  startTime?: BroadcastTimeProps;
  endTime?: BroadcastTimeProps;
  timePrefix?: string;
}

export interface RundownSpaceProps {
  type: rundownElementTypes.space;
  minutes?: number;
}

export interface RundownContentProps {
  worldFeed: [RundownBlockProps | RundownSpaceProps];
  afterWorldFeed: [RundownBlockProps | RundownSpaceProps];
}

export interface RundownSelectMenuProps {
  value: string;
  label: string;
}

export enum RundownSelectMenuValues {
  fridayNight = "friday_night",
  saturdayAfternoon = "saturday_afternoon",
  saturdayNight = "saturday_night",
  sundayLunch = "sunday_lunch",
  sundayAfternoon = "sunday_afternoon",
  sundayNight = "sunday_night",
  mondayNight = "monday_night",
  tuesdayNight = "tuesday_night",
  tuesdayLateNight = "tuesday_late_night",
  wednesdayNight = "wednesday_night",
  wednesdayLateNight = "wednesday_late_night",
}

export const currentJwPlayerId = null;

const breakpointSizes = {
  xxs: 0,
  xs: 320,
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
};

export const breakpoint = {
  xxs: {
    up: "@media only screen and (min-width: " + breakpointSizes.xxs + "px)",
    down: "@media only screen and (max-width: " + breakpointSizes.xxs + "px)"
  },
  xs: {
    up: "@media only screen and (min-width: " + breakpointSizes.xs + "px)",
    down: "@media only screen and (max-width: " + breakpointSizes.xs + "px)"
  },
  s: {
    up: "@media only screen and (min-width: " + breakpointSizes.s + "px)",
    down: "@media only screen and (max-width: " + breakpointSizes.s + "px)"
  },
  m: {
    up: "@media only screen and (min-width: " + breakpointSizes.m + "px)",
    down: "@media only screen and (max-width: " + breakpointSizes.m + "px)"
  },
  l: {
    up: "@media only screen and (min-width: " + breakpointSizes.l + "px)",
    down: "@media only screen and (max-width: " + breakpointSizes.l + "px)"
  },
  xl: {
    up: "@media only screen and (min-width: " + breakpointSizes.xl + "px)",
    down: "@media only screen and (max-width: " + breakpointSizes.xl + "px)"
  }
};

export const finalUrl = "http://products.bundesliga.com";

export enum productLinks {
  liveMatch = "bundesliga-matches/#live-matches",
  relegationPlayoff = "bundesliga-matches/#relegation-play-offs",
  supercupMatch = "bundesliga-matches/#supercup",
  instantHighFeed = "bundesliga-matches/#instant-highlights-feed",
  uhdMatch = "bundesliga-matches/#uhd-matches",
  socialMediaMatchdayFeed = "bundesliga-matches/#social-media-matchday-feed",
  goalArena = "bundesliga-matches/#goal-arena---the-bundesliga-konferenz",

  highLightsShow1 = "match-highlights/#highlights-show-i",
  highLightsShow2 = "match-highlights/#highlights-show-ii",
  matchdayClips = "match-highlights/#matchday-clips",
  sloMoPlayout = "match-highlights/#slow-motion-play-outs",

  preMatchInvu = "match-interviews/#pre-match-interviews",
  inMatchClips = "match-interviews/#pre-match-interviews",
  superFlashInvu = "match-interviews/#super-flash-interviews",
  postMatchInvu = "match-interviews/#post-match-interviews",

  digHighClips = "digital-content/#highlights-clips",
  nearLiveClips = "digital-content/#near-live-clips",
  shortClipsGraphics = "digital-content/#short-clips-&-graphics",
  roundupClips = "digital-content/#round-up-clips",
  socMedContentService = "digital-content/#social-media-content-service",
  localizedClips = "digital-content/#localised-clips",
  buliEngagement = "digital-content/#bundesliga-engagement-campaigns",
  platformSynergy = "digital-content/#bundesliga-platform-synergy-campaigns",
  onlineGames = "digital-content/#bundesliga-voting-tool",

  seasonTrailer = "promo-trailer-content/#season-trailers",
  faimtbTrailer = "promo-trailer-content/#football-as-it's-meant-to-be-trailers",
  topMatchPromo = "promo-trailer-content/#top-match-promotion-clips",
  promoTrailer = "promo-trailer-content/#promotion-trailer-kit",

  seasonTicket = "seasonal-content/#season-ticket",
  buliClubs = "seasonal-content/#bundesliga-clubs",
  seasonSpecials = "seasonal-content/#seasonal-specials",
  buliAnalysis = "seasonal-content/#bundesliga-analysis",
  summerSpecial = "seasonal-content/#summer-break-special",
  localisedStorytellingElementsSeasonTicket = "seasonal-content/#localised-storytelling-elements---season-ticket",

  buliReport = "monthly-content/#bundesliga-report",
  bestOfMonth = "monthly-content/#best-of-the-month",
  invuOfMonth = "monthly-content/#interview-of-the-month",
  localisedStorytellingElementsBestOfTheMonth = "monthly-content/#localised-storytelling-elements---best-of-the-month",
  monthSpecials = "monthly-content/#monthly-specials",
  buliSeries = "monthly-content/#bundesliga-series",

  buliWeekly = "weekly-content/#bundesliga-weekly",
  buliWeeklyLocalised = "weekly-content/#localised-storytelling-elements---bundesliga-weekly",
  dailyNewsfeed = "weekly-content/#daily-news-feed",
  midweekInvus = "weekly-content/#midweek-interviews",
  weeklyNews = "weekly-content/#weekly-content-mailing",
  confFeed = "weekly-content/#press-conference-feed",

  matchInterviews = "match-interviews",

  whatsNew = "../whats-new",

  mediaKit = "promo-trailer-content/#media-kit-&-promotion-trailer-kit",
  mediaDays = "promo-trailer-content/#media-days",
  techServices = "digital-content",
}

// Broadcasts and Related Products
export enum broadcastProductLabels {
  liveMatch = "Live Match",
  instantHighFeed = "Instant Highlights Feed",
  sloMoPlayout = "Slow-Motion Play-Outs",
  digHighClips = "Highlights Clips",
  nearLiveClips = "Near Live Clips",
  shortClipsGraphics = "Short Clips & Graphics",
  preMatchInvu = "Pre-Match Interviews",
  inMatchClips = "In-Match Clips",
  postMatchInvu = "Post-Match Interviews",
  superFlashInvu = "Super-Flash Interviews",
  highlightShow1 = "Highlights Show I",
  highlightShow2 = "Highlights Show II",
  roundupClips1 = "Round-Up Clips I",
  roundupClips2 = "Round-Up Clips II",
  matchdayClips = "Matchday Clips",
  localizedClips = "Localised Clips",
  dailyNewsfeed = "Daily News Feed",
  midweekInvus = "Midweek Interviews",
  topMatchPromo = "Top Match Promotion Clips",
  topMatchPromoNetxtMatchday = "Top Match Promotion Clips (subsequent matchday)",
  buliWeekly = "Bundesliga Weekly",
  buliWeeklyLocalised = "Bundesliga Weekly - Localised Elements",
  confFeed = "Press Conference Feed",
  bestOfMonth = "Best of the Month",
  bestOfTheMonthLocalisedElements = "Best of the Month - Localised Elements",
  invuOfMonth = "Interview of the Month",
  buliReport = "Bundesliga Report",
  buliClubs = "Bundesliga Clubs",
  seasonSpecials = "Seasonal Specials",
  monthSpecials = "Monthly Specials",
  buliSeries = "Bundesliga Series",
  buliAnalysis = "Bundesliga Analysis",
  summerSpecial = "Summer Break Special",
  seasonTicket = "Season Ticket",
  seasonTicketLocalisedElements = "Season Ticket - Localised Elements",
  faimtbTrailer = "Football as it's meant to be-Trailer",
  seasonTrailer = "Season Trailers",
  promoTrailer = "Promotion Trailer Kit",
  socMedContentService = "Short Clips & Graphics",
  weeklyNews = "Weekly Content Mailing",
  supercupMatch = "Supercup Live Match",
  relegationPlayoff = "Relegation Play-Offs Live Match",
  uhdMatch = "UHD Live Match",
  buliEngagement = "Bundesliga Campaigns",
  platformSynergy = "Bundesliga Platform Synergy Campaigns",
  matchInterviews = "Match Interviews",
  onlineGames = "Online Games",
  interactiveFeed = "Interactive Feed",
  socialMediaMatchdayFeed = "Social Media Matchday Feed",
  goalArena = "Goal Arena - the Bundesliga Konferenz",
  customisedClipsCompilations = "Customised Clips (Compilations)",
  customisedClipsInMatch = "Customised Clips (In-Match)",
  customisedClips = "In-Match & Customised Clips",
  mediaKit = "Media Kit & Promotion Trailer Kit",
  mediaDays = "Media Days",
  techServices = "Technology Services",
}

export type ProductLinkType = productLinks;

export const COOKIE_KEY = "dfl-usage-shown-1.0";

export enum linksListItemsDesktopColumnsLayout {
  multi = "multi-column",
  single = "single-column",
}

export enum breakpoints {
  mobile = 320,
  tablet = 768,
  desktop = 1024,
  desktopHd = 1440,
  desktopFullHd = 1920,
  desktopWideQuadHd = 2560,
  desktopUltraWideQuadHd = 3440,
  desktop4k = 4096,
}

export enum componentBreakpoints {
  categoryList = breakpoints.desktopHd,
  navigation = breakpoints.desktopHd,
}

export enum mobilePaddings {
  mobile = 32,
  tablet = 82,
}

export enum contentTypes {
  video = "localized_video",
  image = "localized_image",
  threeColumn = "threeColumn",
}
export type ContentType = contentTypes;

export enum threeColumnContentTypes {
  image = "image",
  video = "video",
}
export type ThreeColumnContentType = threeColumnContentTypes;
